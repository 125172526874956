<app-navigation>
  <main>
    <h2>User Requests</h2>
    <section style="margin-bottom: 2rem;">
      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput placeholder="ecad-pt-dev-tools" #input maxlength="50" appFormInput (keyup)="approvalsListFilter($event)">
      </mat-form-field>
      <div class="loader" *ngIf="!loadTable">
        <mat-spinner *ngIf="!loadTable"></mat-spinner>
        <!-- <div style="max-width: 360px;text-align: center;">
          We are sorry, but we cannot show any results at the moment.
        </div> -->
      </div>
      <mat-card>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end" (selectedTabChange)="onTabChange($event)"
          style="flex: 1;">
          <mat-tab label="Pending"></mat-tab>
          <mat-tab label="History"></mat-tab>
        </mat-tab-group>
        <mat-card-content style="overflow: auto;min-height: 69vh;">
          <table mat-table [ngStyle]="{'opacity': loadTable ? '1' : '0.3' }" [dataSource]="userRequestTableDataSource"matSort>

            <ng-container matColumnDef="summary">
              <th mat-header-cell *matHeaderCellDef> Summary </th>
              <td mat-cell *matCellDef="let row">
                {{row.summary ? row.summary : "No Summary added"}}
              </td>
            </ng-container>

            <ng-container matColumnDef="draftName">
              <th mat-header-cell *matHeaderCellDef> Draft Name </th>
              <td mat-cell *matCellDef="let row">
                <a style="color: #00c853; text-decoration: none;"
                  [routerLink]="['/review',row.dguid]">{{row.draftName}}</a>
              </td>
            </ng-container>

            <ng-container matColumnDef="subs">
              <th mat-header-cell *matHeaderCellDef> Subscription </th>
              <td mat-cell *matCellDef="let row">
                {{row.subscriptionName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="requestorName">
              <th mat-header-cell *matHeaderCellDef> Requester </th>
              <td mat-cell *matCellDef="let row">
                {{row.requesterName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.currentPendingLevel!==-1;else statusBlock">
                <span class="pending" [matMenuTriggerFor]="menu">
                  Pending from {{row.currentPendingLevel!==-1 ? 'L'+row.currentPendingLevel: ""}}
                  <i-tabler name="chevron-down" style="width: 20px;"></i-tabler>
                  <mat-menu #menu="matMenu" style="padding: 0px !important;">
                    <div mat-menu-item (click)="changeApprovalStatus('Approved',row.dguid)">
                      <span class="approve">
                        <mat-icon class="icon" style="color: #00c853;">check</mat-icon>Approve Request
                      </span>
                    </div>
                    <div mat-menu-item (click)="changeApprovalStatus('Rejected',row.dguid)">
                      <span class="reject">
                        <mat-icon class="icon" style="color: #f44336;">close</mat-icon>Reject Request
                      </span>
                    </div>
                    <mat-divider></mat-divider>
                    <a mat-menu-item [routerLink]="['/review', row.dguid]">
                      <mat-icon>visibility</mat-icon>
                      <span>Review</span>
                    </a>
                  </mat-menu>
                </span>
                </ng-container>
                <ng-template #statusBlock>
                 <span [ngStyle]="{
                  'color': row.currentStatus === 'Approved' ? '#00c853' : '#f44336',
                  'background-color': row.currentStatus === 'Approved' ? 'rgba(0, 200, 83, 0.1)' : 'rgba(244, 67, 54, 0.1)',
                  'padding':'8px 12px','border-radius':'16px'}"> 
                  {{row.currentStatus}}</span>
                </ng-template>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Created </th>
              <td mat-cell *matCellDef="let row">
                {{row.requestDate | epochToDate}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="userRequestTableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: userRequestTableColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell">No Requests</td>
            </tr>
          </table>
          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-footer>
          <mat-paginator #userRequestTablePaginator="matPaginator" [hidePageSize]="true" [showFirstLastButtons]="false"
            [pageSizeOptions]="[20]" showFirstLastButtons>
          </mat-paginator>
        </mat-card-footer>
      </mat-card>
    </section>
  </main>
</app-navigation>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CoreService } from "src/app/components/shared/snackbar/snackbar.service";
import { Observable } from 'rxjs';
import { AesencryptionService } from 'src/services/encryption.service';
import { RoleData } from './components/pages/roles-table/roles';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, 
    private _coreService: CoreService, 
    private aesencryptionService: AesencryptionService
    ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      !JSON.parse(
        this.aesencryptionService.decryptUsingAES256(
          sessionStorage.getItem('userdetails')
        )
      )
    ) {
      this.router.navigate(['login']);
      return false;
    }

    // Global variable for jwtToken
    const userRoles: RoleData = JSON.parse(
      this.aesencryptionService.decryptUsingAES256(
        sessionStorage.getItem('userRoles')
      ) || ''
    );
    if (!this.hasAdminModulePermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasTenantModulePermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasBlueprintExplorerPermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasBlueprintNewSubscriptionPermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasBlueprintApprovalPermission(userRoles, state.url)) {
      return false;
    }
    if (!this.hasBlueprintDeploymentPermission(userRoles, state.url)) {
      return false;
    }
    //Not Working
    const noBlueprintEditPermissions = [
      !userRoles.blueprintCreate,
      !userRoles.blueprintModify,
      !userRoles.blueprintDelete,
    ];

    // Check user's permissions
    if (
      state.url === '/blueprint/edit/:id' &&
      noBlueprintEditPermissions.every((permission) => permission)
    ) {
      this.router.navigate(['dashboard'], { queryParams: route.queryParams });
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }

    if (!this.hasFormbuilderPermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasFormbuilderCreatePermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasGrafanaUserPermission(userRoles, state.url)) {
      return false;
    }

    if (!this.hasAdminRoleModulePermission(userRoles, state.url)) {
      return false;
    }
    if (!this.hasServiceAccountPermission(userRoles, state.url)) {
      return false;
    }

    return true;
  }
  //Done
  private hasAdminModulePermission(userRoles: RoleData, url: string): boolean {
    if (url === '/admin' &&!userRoles.adminModule) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }

  private hasTenantModulePermission(userRoles: RoleData, url: string): boolean {
    if (url === '/tenents' &&!userRoles.adminModule) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }

  //Done
  private hasBlueprintExplorerPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/blueprint/explorer' &&!userRoles.blueprintView) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Done
  private hasBlueprintNewSubscriptionPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/blueprint/subscription/new' &&!userRoles.blueprintCreate) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Done
  private hasBlueprintApprovalPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/approval' && !userRoles.blueprintApproval_L1 && !userRoles.blueprintApproval_DevOps) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  private hasBlueprintDeploymentPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/deployment' && !userRoles.adminDevOps) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Working
  private hasFormbuilderPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/formbuilder' &&!userRoles.formbuilderView) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Working
  private hasFormbuilderCreatePermission(userRoles: RoleData, url: string): boolean {
    if (url === '/formbuilder/new' &&!userRoles.formbuilderCreate) {
      this.router.navigate(['formbuilder']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Working
  private hasGrafanaUserPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/monitoring' &&!userRoles.grafanaUser) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  //Working
  private hasAdminRoleModulePermission(userRoles: RoleData, url: string): boolean {
    if (url === '/roles' &&!userRoles.adminRoleModule) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
  private hasServiceAccountPermission(userRoles: RoleData, url: string): boolean {
    if (url === '/service' &&!userRoles.serviceAccount) {
      this.router.navigate(['dashboard']);
      this._coreService.openSnackBar('Unauthorized', 5000, 'warn-snackbar');
      return false;
    }
    return true;
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlueprintsService } from 'src/services/blueprints.service';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { HttpResponse } from '@angular/common/http';
import { ResourceDataService } from 'src/services/resource-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { BlueprintDataService } from 'src/services/blueprint-data.service';

@Component({
  selector: 'app-infra-cost-dialog',
  templateUrl: './infra-cost-dialog.component.html',
  styleUrls: ['./infra-cost-dialog.component.scss'],
})
export class InfraCostDialogComponent implements OnInit {
  tableColumns: string[] = ['name', 'type', 'cost'];
  checked = false;
  status = false;
  infracostResponseRecieved = false;
  isTemplateDraft: boolean = false;
  summary = '';
  tables = [
    {
      title: 'Subscription to be created: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be created: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be updated: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be deleted: ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Resources to be synced: ',
      dataSource: new MatTableDataSource<any>(),
    },
  ];
  totalCurrentCost: any;
  totalPreviousCost: any;
  subscriptionFullyQualifiedName: any;
  startTime!: number;
  dialogClosed: boolean=false;

  constructor(
    private _blueprintService: BlueprintsService,
    private _coreService: CoreService,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<InfraCostDialogComponent>,
    private resourceDataService: ResourceDataService,
    private _blueprintDataService: BlueprintDataService,
  ) {
    this.isTemplateDraft = data.isTemplateDraft; // Initialize the isTemplateDraft flag from the dialog data
  }

  ngOnInit() {
    this.loadCostPolling();
    this.startTime = new Date().getTime();
  }

  // Method to load cost polling
  loadCostPolling() {
    if (this.dialogClosed) return;
    this._blueprintService.getBpCostPolling(this.data.dguid).subscribe(
      async (response: HttpResponse<any>) => {
        this.status = response.body.status;
        if (!this.status) {
          const currentTime = new Date().getTime();
          const elapsedTime = (currentTime - this.startTime) / 1000; // calculate elapsed time in seconds
          if (elapsedTime < 600) { // Condition to stop polling after 5 minutes
            await new Promise((resolve) => setTimeout(resolve, 15000));// Start polling every 15 seconds
            this.loadCostPolling();
          }
          else {
            this._coreService.openSnackBar('Time Out. Please try again later.',1000,'warn-snackbar');
            this._dialogRef.close();
          }
        } else {
          this.fetchCosts();
        }
      },
      () => {
        this._coreService.openSnackBar('Error Polling Costs',1000,'warn-snackbar');
        this._dialogRef.close();
      }
    );
  }

  close() {
    this.dialogClosed = true;
    if(!this.infracostResponseRecieved){
    this._coreService.openSnackBar('Cost Calculation has been disabled.',1000,'default-snackbar');
    }
  }

  // Method to fetch costs
  fetchCosts() {
    this._blueprintService.getBpCostJson(this.data.dguid).subscribe(
      (response: HttpResponse<any>) => {
        this.isTemplateDraft =
        response.body.userRequestInfo.state === 'template';
        const infracostjson = response.body;
        this.getInfracost(infracostjson);
      },
      () => {
        this._coreService.openSnackBar(
          'Error Fetching Costs',
          1000,
          'warn-snackbar'
        );
      }
    );
  }

  getInfracost(infracostjson:any) {
    if (!infracostjson) {
      return;
    }
    let totalPreviousCost = infracostjson.subscriptionData.resourceData.userActionType === "" ? infracostjson.subscriptionData.resourceData.currentCost : infracostjson.subscriptionData.resourceData.previousCost;
    let totalCurrentCost = infracostjson.subscriptionData.resourceData.currentCost;
    infracostjson.resourceInventoryData.forEach(
      (resourceGroup: { resourceList: any[] }) => {
        totalPreviousCost += resourceGroup.resourceList.reduce(
          (sum, resource) =>
            sum + (resource.resourceData.userActionType === "" ? resource.resourceData.currentCost : resource.resourceData.previousCost),
          0
        );
        totalCurrentCost += resourceGroup.resourceList.reduce(
          (sum, resource) => sum + resource.resourceData.currentCost,
          0
        );
      }
    );
    this.totalCurrentCost = totalCurrentCost;
    this.totalPreviousCost = totalPreviousCost;
    const result = this.resourceDataService.bpReviewData(infracostjson);
    this.subscriptionFullyQualifiedName =
      infracostjson.subscriptionData.resourceData.resourcePropertyList.find(
        (property: { propertyName: string }) =>
          property.propertyName === 'subscriptionFullyQualifiedName'
      ).propertyValue;
    infracostjson.subscriptionData.resourceData.resourceName = this.subscriptionFullyQualifiedName;
    if (infracostjson.subscriptionData.resourceData.userActionType === "create") {
      this.tables[0].dataSource.data.push(infracostjson.subscriptionData.resourceData);
    }
    this.tables[1].dataSource.data = result.created;
    this.tables[2].dataSource.data = result.updated;
    this.tables[3].dataSource.data = result.deleted;
    this.tables[4].dataSource.data = result.synced;
    this.infracostResponseRecieved = true;
  }

  changeValue(value: any) {
    this.checked = !value;
  }

  submitForApproval() {
    const dguid = this.data.dguid;
    this._blueprintService.postBPApproval(dguid, this.summary).subscribe(
      (response: HttpResponse<any>) => {
        const message = response.body;
        this._coreService.openSnackBar(message, 5000,'success-snackbar');
        this._blueprintDataService.setDguid(this.data.dguid);
        this.route.navigateByUrl('/blueprint/explorer');
      },
      (error) => {
        let errorMessage = error.error
          ? error.error.message
          : 'Error Sending Blueprint for Approval';
        this._coreService.openSnackBar(errorMessage, 2000,'warn-snackbar');
      }
    );
  }

  hasData(): boolean {
    return this.tables.some((table) => table.dataSource.data.length > 0);
  }

  getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }
}

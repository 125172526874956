import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge,of as observableOf, Subject} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { DeploymentsService } from './deployments.service';
import { RoleData } from '../roles-table/roles';
import { AesencryptionService } from 'src/services/encryption.service';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss']
})

export class DeploymentComponent implements AfterViewInit {
  userRoles: RoleData;
  displayedColumns: string[] = ['summary','draftName', 'subscriptionName','userName', 'subStatus', 'createdOn'];
  data: BlueprintIssue[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  
  filterValue = '';
  private filterSubject = new Subject<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private _deploymentService: DeploymentsService, private aesencryptionService: AesencryptionService,
  ){
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filterSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this._deploymentService!.getBpDeployments(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.filterValue
          ).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero
          this.resultsLength = data.length;
          return data;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  applyFilter(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.filterValue = value.trim().toLowerCase();
    this.filterSubject.next(this.filterValue);
  }

}

export interface BlueprintIssue {
  dguid: string
  draftName: string
  subscriptionName: string
  userName: string
  status: string
  subStatus: string
  summary: any
  createdOn: string
  ticketId: any
  lastUpdated: string
  archive: boolean
}
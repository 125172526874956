<h2 mat-dialog-title>{{data.isCopy?'Copy Template' : 'Clone Subscription'}}</h2>
<div mat-dialog-content>
    <mat-progress-bar *ngIf="cloneLoader" mode="indeterminate"></mat-progress-bar>
    <p>{{data.isCopy ? 'On Copying, you will create a copy of the existing template to manage your Infrastructure.' : 
    'On Cloning, you will create a copy of your existing Subscription to manage your Infrastructure.'}}</p>
    <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px" *ngIf="data.isCopy"> 
        <input matInput placeholder="New Subscription Name"
            [(ngModel)]="subName" maxlength="9" appFormInput required #input>
        <mat-hint style="color: aliceblue;" align="end">{{input.value.length}}/9</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%; margin-top: 10px">
        <input matInput placeholder="Draft Name"
            [(ngModel)]="draftName" maxlength="30" appFormInput required>
    </mat-form-field></div>
<div mat-dialog-actions style="justify-content: end;">
    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button color="primary" type="submit" mat-flat-button (click)="saveDraftName()" [disabled]="!draftName">{{data.isCopy ? 'Copy' : 'Clone'}}</button>
</div>
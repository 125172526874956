import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlueprintDataService {

  dguid: string="";

  private myBehaviorSubject = new BehaviorSubject({});
  
  private isresourceIconSelectSetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isResourceIconSet$: Observable<boolean> = this.isresourceIconSelectSetSubject.asObservable();
  
  private retriggerDrawerSubject = new Subject<void>();
  public retriggerDrawerObservable$ = this.retriggerDrawerSubject.asObservable();

  public retriggerDrawerToggle() {
    this.retriggerDrawerSubject.next();
  }

  setBlueprintData(value: string) {
    this.myBehaviorSubject.next(value);
  }

  getBlueprintData() {
    return this.myBehaviorSubject.asObservable();
  }
  
  setDguid(Dguid:string) {
    this.dguid=Dguid;
  }
  
  getDguid() {
    return this.dguid;
  }

  public setIsVariableSet(value: boolean): void {
    this.isresourceIconSelectSetSubject.next(value);
  }
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BlueprintsService } from 'src/services/blueprints.service';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { UsersubscriptionService } from 'src/services/usersubscription.service';
import { catchError , of } from 'rxjs';
interface ResponseBody {
  value: any[];
}

export interface DialogData {
  subid: string;
}

@Component({
  selector: 'app-blueprint-clone-dialog',
  templateUrl: './blueprint-clone-dialog.component.html',
  styleUrls: ['./blueprint-clone-dialog.component.scss']
})

export class BlueprintCloneDialogComponent {
  subName='';
  draftName = '';
  cloneLoader = false;
  button= false;

  constructor(public dialogRef: MatDialogRef<BlueprintCloneDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _blueprintService: BlueprintsService,
    private _coreService: CoreService,
    private router: Router,
    private usersubscription: UsersubscriptionService,
    ) {}

  saveDraftName() {
    this.button = true;
    this.isDuplicateDraftName(this.data.subid, this.draftName)
      .then((isDuplicate) => {
        if (isDuplicate) {
          this._coreService.openSnackBar('Duplicate draft name found. Please enter a unique name.', 1000, 'warn-snackbar');
        } else {
          this.clone();
        }
      })
      .catch(() => {
        this._coreService.openSnackBar('Error fetching subscriptions', 2000, 'warn-snackbar');
      });
  }

  clone() {
    this.cloneLoader = true;
    if(!this.data.isCopy){
    this._blueprintService.cloneBlueprint(this.data.subid, this.draftName).subscribe(
      (res: any) => {
        this.cloneLoader = false;
        this.router.navigate(['/blueprint/edit', res.body])
        this.dialogRef.close(this.draftName);
        this._coreService.openSnackBar('Draft Created Successfully', 1000, 'success-snackbar');
      },
      () => {
        this.cloneLoader = false;
        this._coreService.openSnackBar('Error Cloning Subscription', 1000, 'warn-snackbar');
      }
    );
  }
  else{
    this._blueprintService.copyBlueprint(this.data.dguid,this.draftName,this.subName).subscribe(
      (res: any) => {
        this.cloneLoader = false;
        this.router.navigate(['/blueprint/edit', res.body.dguid])
        this.dialogRef.close(this.draftName);
        this._coreService.openSnackBar('Draft Copied Successfully', 1000, 'success-snackbar');
      },
      () => {
        this.cloneLoader = false;
        this._coreService.openSnackBar('Error Cloning Subscription', 1000, 'warn-snackbar');
      }
    );
  }
  }

  isDuplicateDraftName(subscriptionId: string, draftName: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.usersubscription.getSubscriptionList(false).pipe(
        catchError(error => {
          this._coreService.openSnackBar('Error fetching subscriptions', 2000, 'warn-snackbar');
          return of(null);
        })
      ).subscribe((res: any) => {
        const subscriptions = (res.body as ResponseBody).value;
        const subscription = subscriptions.find((sub: { subid: string; }) => sub.subid === subscriptionId);
        if (subscription?.drafts) {
          const draft = subscription.drafts.find((draft: { name: string; }) => draft.name === draftName);
          resolve(draft !== undefined);
        } else {
          resolve(false);
        }
      });
    });
  }
}
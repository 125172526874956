
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ResourceTypeDialogComponent } from '../../dialog/formbuilder-resource-type-dialog/formbuilder-resource-type-dialog.component';
import { FormbuilderService } from 'src/services/formbuilder.service';
import { NavigationService } from 'src/services/navigation.service';
import { DialogService } from 'src/services/confirm-dialog.service';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';
import { HttpResponse } from '@angular/common/http';
import { AesencryptionService } from 'src/services/encryption.service';
import { ResourceData, ResourceInventoryData } from 'src/app/models/IResourceDataModel';
import { RoleData } from '../roles-table/roles';
import { AddTenantDialogComponent } from '../../dialog/add-tenant-dialog/add-tenant-dialog.component';

interface formList {
  formId: string;
  resourceDisplayName: string;
  isActive: boolean;
}

interface tenantData {
  tenantId: string;
  tenantName: string;
  formList: formList[];
}

@Component({
  selector: 'app-formbuilder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.scss'],
})

export class FormbuilderComponent implements OnInit {
  resourceTypeColumns: string[] = ['resourceType', 'action'];
  resourceTypeDataSource!: MatTableDataSource<ResourceData>;
  @ViewChild('resourceTypePaginator') resourceTypePaginator!: MatPaginator;

  resourceFormsColumns: string[] = [
    'formName',
    'resourceType',
    'edit',
    'publish',
  ];
  resourceFormsDataSource!: MatTableDataSource<ResourceData>;
  @ViewChild('resourceFormsPaginator') resourceFormsPaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  userRoles: RoleData;
  loadTable = false;

  tenantDisplayedColumns: string[] = [];
  tenantDataSource: MatTableDataSource<tenantData> = new MatTableDataSource();
  tenantEnabledRow: any | null = null;
  tenantEditingRow: any | null = null;

  constructor(
    private _dialog: MatDialog,
    private _formbuilderService: FormbuilderService,
    private navigationService: NavigationService,
    private _coreService: CoreService,
    private dialog: DialogService,
    private aesencryptionService: AesencryptionService
  ) {
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngOnInit(): void {
    this.navigationService.setTitle('Formbuilder');
    this.resourceTypeTableData();
    this.formsTableData();
  }

  resourceTypeTableData() {
    this._formbuilderService
      .getResourceTypesList()
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.resourceTypeDataSource = new MatTableDataSource(response.body.formBuilderResourceTypesList);
          this.resourceTypeDataSource.sort = this.sort;
          this.resourceTypeDataSource.paginator = this.resourceTypePaginator;
          this.loadTable = true;
        },
        error: () => {
          this._coreService.openSnackBar('Error while fetching resource types', 2000, 'warn-snackbar'); 
        }
      });
  }
  formsTableData() {
    this._formbuilderService.getFormsList().subscribe({
      next: (res: any) => {
      this.resourceFormsDataSource = new MatTableDataSource(
        res.body.formsList
      );
      this.resourceFormsDataSource.sort = this.sort;
      this.resourceFormsDataSource.paginator = this.resourceFormsPaginator;
      this.loadTable = true;
      },
      error: () => {
      this._coreService.openSnackBar('Error while fetching forms',2000,'warn-snackbar');
      }
    });
  }
  resourceTypeDialog() {
    const dialogRef = this._dialog.open(ResourceTypeDialogComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resourceTypeTableData();
      }
    });
  }
  editResourceType(resource: ResourceInventoryData) {
    const resourceType = resource.resourceType;
    this._formbuilderService.getFormBuilderResourceType(resourceType).subscribe({
      next: (response: HttpResponse<any>) => {
        const data = response.body;
        const dialogRef = this._dialog.open(ResourceTypeDialogComponent, {data});
        dialogRef.afterClosed().subscribe({
          next: (val) => {
            if (val) {
              this.resourceTypeTableData();
            }
          },
        });
      },
      error: () => {
        this._coreService.openSnackBar('Error while editing resource type', 2000, 'warn-snackbar');
      }
    });
  }

  togglePublishForm(id: string, publish: boolean) {
    const dialogConfig = {
      title: publish ? 'Publish Form' : 'Unpublish Form',
      message: publish ? 'Forms Will be Available on Blueprints and Self Services Portal!' : 'Forms Will not be available on Blueprints and Self Services Portal!',
      confirmCaption: publish ? 'Publish' : 'Confirm',
      cancelCaption: 'Cancel',
    };

    this.dialog
      .confirmDialog(dialogConfig)
      .subscribe({
      next: (confirmed) => {
        if (confirmed) {
        this._formbuilderService.putFormBuilderPublishForm(id, publish).subscribe({
          next: () => {
            this.formsTableData();
            this._coreService.openSnackBar(publish ? 'Form Published Successfully' : 'Form Unpublished', 1000);
          },
          error: () => {
            this._coreService.openSnackBar('Error while publishing form', 2000, 'warn-snackbar');
          }
          });
        }
      }
      });
  }

  resourceTypeList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resourceTypeDataSource.filter = filterValue.trim().toLowerCase();

    if (this.resourceTypeDataSource.paginator) {
      this.resourceTypeDataSource.paginator.firstPage();
    }
  }

  formsList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resourceFormsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.resourceFormsDataSource.paginator) {
      this.resourceFormsDataSource.paginator.firstPage();
    }
  }

}

import { Component, Inject, OnInit} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormbuilderService } from "src/services/formbuilder.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AesencryptionService } from "src/services/encryption.service";
import { CoreService } from "../../shared/snackbar/snackbar.service";
import { RoleData } from "../../pages/roles-table/roles";
@Component({
  selector: 'app-formbuilder-field-dialog',
  templateUrl: './formbuilder-field-dialog.component.html',
  styleUrls: ['./formbuilder-field-dialog.component.scss']
})
export class FormBuilderFieldDialogComponent implements OnInit {
  newFieldFormGroup: FormGroup;

  dataType: string[] = ["string", "integer"];
  source = [
    { value: "azureJson", viewValue: "Azure Json" },
    { value: "tfState", viewValue: "Tf State" }
  ];
  userInput = false;
  ReadOnly = false;
  userRoles: RoleData;
  FormbuilderAddPermissions = false;
  FormbuilderEditPermissions = false;
  formresponse: any;
  
  constructor(
    private _fb: FormBuilder,
    private _formbuilderService: FormbuilderService,
    private _dialogRef: MatDialogRef<FormBuilderFieldDialogComponent>,
    private coreService: CoreService,
    private aesencryptionService: AesencryptionService,
    
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.newFieldFormGroup = this._fb.group({
      propertyName: "",
      propertyNameAlias: "",
      dataType: "",
      itemList: "",
      type: new FormControl({ value: "", disabled: true }, [Validators.required]),
      userInput: false,
      propertyValue: "",
      source: new FormControl(""),
      editable: false,
      id: null,
      path: "",
    });
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngOnInit() {
    if (this.data) {
      this._formbuilderService.getFormBuilderResourceField(this.data).subscribe(
        (response) => {
          this.formresponse = response.body;
          this.newFieldFormGroup.patchValue(this.formresponse);
          const propertyNameFieldControl = this.newFieldFormGroup.get('propertyName');
          const propertytypeFieldControl = this.newFieldFormGroup.get('source')?.value;
          if (propertyNameFieldControl) {
            propertyNameFieldControl.disable();
          }
          if (propertytypeFieldControl) {
            this.onSelectionChanged(propertytypeFieldControl);
          }
        },
        (error) => {
          this.coreService.openSnackBar('Error while Fetching Field', 2000, 'warn-snackbar');
        }
      );
    }

    this.FormbuilderAddPermissions = (
      this.userRoles.formbuilderCreate || //Should be only this
      this.userRoles.formbuilderPublish
    );
    
    this.FormbuilderEditPermissions = (
      this.userRoles.formbuilderEdit || //Should be only this
      this.userRoles.formbuilderPublish
    );
  }

  onSelectionChanged( value: string) {
    if (value === 'azureJson') {
      this.newFieldFormGroup.get('type')?.disable();
    }
    else {
      this.newFieldFormGroup.get('type')?.enable();
    }
  }

  onFormSubmit() {
    if (this.newFieldFormGroup.valid) {
      const formValue = this.newFieldFormGroup.getRawValue();
      const formResourceAction = this.data ? this._formbuilderService.putFormBuilderResourceField(formValue) : this._formbuilderService.postFormBuilderResourceField(formValue);
      formResourceAction.subscribe(
        () => {
          this.coreService.openSnackBar('Successfully Updated Field', 2000);
          this._dialogRef.close(this.newFieldFormGroup.value); // Pass the formValue back to the dialog
        },
        () => {
          this.coreService.openSnackBar('Error while Updating Field', 2000, 'warn-snackbar');
        }
      );
    }
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppfamilyComponent } from '../../dialog/appfamily/appfamily.component';
import { FormbuilderService } from 'src/services/formbuilder.service';
import { NavigationService } from 'src/services/navigation.service';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class PublishFormComponent implements OnInit{
  loadTable = false;
  appFamilyColumns: string[] = ['appFamilyName', 'grafanaId', 'edit'];
  appFamilyDataSource!: MatTableDataSource<any>;
  @ViewChild('appFamilyPaginator') appFamilyPaginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private _dialog: MatDialog,
    private _formbuilderService: FormbuilderService,
    private navigationService: NavigationService,
    private coreService: CoreService,
  ) { }

  ngOnInit() {
    this.navigationService.setTitle('Admin Page');
    this.appFamilyTableData();
  }

  appFamilyTableData() { //checked
    this._formbuilderService.getAppFamilyList().subscribe({
      next: (res: any) => {
        this.appFamilyDataSource = new MatTableDataSource(res);
        this.appFamilyDataSource.sort = this.sort;
        this.appFamilyDataSource.paginator = this.appFamilyPaginator;
        this.loadTable = true;
      },
      error: () => {
        this.coreService.openSnackBar('Error in fetching App Family List', 1000, 'warn-snackbar');
      }
    });
  }

  appFamilyDialog() {
    const dialogRef = this._dialog.open(AppfamilyComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appFamilyTableData();
      }
    }
    )
  }

  appFamilyList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.appFamilyDataSource.filter = filterValue.trim().toLowerCase();

    if (this.appFamilyDataSource.paginator) {
      this.appFamilyDataSource.paginator.firstPage();
    }
  }

}
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  GET_USER_DETAILS_URL = environment.API_URL + 'Login';
  GET_JWT_TOKEN_URL = environment.API_URL + 'JwtToken';
  
  constructor(private http: HttpClient, private router: Router, private cookie: CookieService) { }
  
  getLogin(accessToken: string): Observable<HttpResponse<string>> {
    const headers = new HttpHeaders({
      'Authorization': accessToken,
    });
    const requestOptions: object = {
      headers: headers,
      observe: 'response',
      responseType:'text'
    }
    return this.http.get<HttpResponse<string>>(this.GET_USER_DETAILS_URL, requestOptions);
  }
}
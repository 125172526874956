import { Component, ElementRef,  OnInit,  ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormbuilderService } from "../../../../../services/formbuilder.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilderFieldDialogComponent } from "../../../dialog/formbuilder-field-dialog/formbuilder-field-dialog.component";
import { DialogService } from "src/services/confirm-dialog.service";
import { EmptyResourceTemplate, ResourcePropertyList } from "src/app/models/empty-resource-template";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CoreService } from "../../../shared/snackbar/snackbar.service";
import { HttpResponse } from "@angular/common/http";
import { Location } from '@angular/common';
import { MatStepper } from "@angular/material/stepper";
import { BlueprintsService } from "src/services/blueprints.service";
import { BlueprintDataService } from "src/services/blueprint-data.service";
import { AesencryptionService } from "src/services/encryption.service";
import { Base64Service } from "src/services/base64.service";
import { ResourceInventoryData } from "src/app/models/IResourceDataModel";


@Component({
  selector: 'app-resource-forms',
  templateUrl: './resource-forms.component.html',
  styleUrls: ['./resource-forms.component.scss'],
})
export class ResourceFormsComponent implements OnInit{
  resourceTypeColumns: string[] = [
    'formField',
    'userInput',
    'editable',
    'itemList',
    'remove',
  ];
  existingFieldColoumns: string[] = ['existingField', 'add', 'edit'];
  newresourceTemplateForm: any = FormGroup;
  ResourceForm: any = [];
  dataSource!: MatTableDataSource<any>;
  emptyresourceProptertyjson = [];
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('editor') private editor!: ElementRef<HTMLElement>;
  @ViewChild('point_2') private my_point_2!: ElementRef;
  @ViewChild('point_3') private my_point_3!: ElementRef;
  @ViewChild('point_4') private my_point_4!: ElementRef;
  currentformresponse: any;
  finalformresponse: any = {
    resourcePropertyList: [],
  };
  resourceTemplate: EmptyResourceTemplate = {};
  defualtLength = 0;
  formpropertytablearray: any[] = [];
  resourcepropertarray: any[] = [];
  resourcepropertylistarray: any[] = [];

  formFieldsTableSource!: MatTableDataSource<{ property: string }>;

  resourcetypes: any;
  resourceVariables: any[] = [];

  currentFormId = '';
  randTicketId = '';
  counter!: number;
  dialogData: any;
  blueprintJson: any;
  formdetails: any[] = [];
  uguid = '';
  resourceInventoryData: ResourceInventoryData[] = [];
  currentResourcePropertyList: ResourcePropertyList[] = [];
  inventoryformgroup: any = FormGroup;
  dguid = '';
  displayName = '';
  resourceTypedisplayName = '';
  isLoading = true;
  pyScriptEncoded = '';
  scriptResult = '';
  updatedVariableBankList: any;

  constructor(
    private _dialog: MatDialog,
    private _formbuilderService: FormbuilderService,
    private _coreService: CoreService,
    private dialogService: DialogService,
    private _fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _blueprintService: BlueprintsService,
    private blueprintDataService: BlueprintDataService,
    private aesencryptionService: AesencryptionService,
    private base64Service: Base64Service,
    private location: Location,
  ) {
    this.newresourceTemplateForm = this._fb.group({
      resourceType: [''],
      gitRef: [''],
      tfStateFolderName: [''],
      resourceNameFormula: [''],
      resourceDisplayName: [''],
      resourceIconUrl: [''],
      dependsOn: [''],
    });

    this.inventoryformgroup = this._fb.group({
      propertyName: this._fb.control('default value'),
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.getResourceTypeDropdown();
    this.randomizeTicketId();
    this.currentFormId = this.activatedRoute.snapshot.params['id'] || null;
    this.counter = this.currentFormId ? 1 : 0;
    this.currentFormId ? this.GetExistingResourceTemplate() : this.GetResourceTemplate();
  }

  // ngAfterViewInit(): void {
    // ace.config.set('fontSize', '18px');
    // ace.config.set(
    //   'basePath',
    //   'https://unpkg.com/ace-builds@1.26.0/src-noconflict'
    // );
    // const aceEditor = ace.edit(this.editor.nativeElement);
    // aceEditor.setTheme('ace/theme/clouds_midnight');
    // aceEditor.setOptions({
    //   enableAutoIndent: true,
    //   wrapBehavioursEnabled: true,
    //   cursorStyle: 'wide',
    // });
    // aceEditor.session.setMode('ace/mode/python');
    // aceEditor.session.setValue('');
    // aceEditor.on('change', () => {
    //   const pyScript = aceEditor.getValue();

    //   this.pyScriptEncoded = btoa(pyScript);
    // });
  // }

  GetResourceTemplate(): void {
    this._formbuilderService.getEmptyResourceTemplate().subscribe({
      next: (res : any) => {
        this.resourceTemplate = res.body;
        this.currentFormId = res.body.formBuilderResourceData.formId;
        this.resourcePropertyTable();
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Resource Template', 2000, 'warn-snackbar');
      }
    });
  }

  GetExistingResourceTemplate(): void {
    this._formbuilderService.getExistingResourceTemplate(this.currentFormId).subscribe({
      next: (response: any) => {
        const { formBuilderResourceData } = response.body;
        this.resourceTemplate = response.body;
        if (formBuilderResourceData) {
          formBuilderResourceData.resourceIconUrl = this.base64Service.decode(formBuilderResourceData.resourceIconUrl);
        }
        this.updateFormAndTable(formBuilderResourceData);
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Resource Template', 2000, 'warn-snackbar');
      }
    });
  }

  private updateFormAndTable(formBuilderResourceData: any): void {
    this.newresourceTemplateForm.patchValue(formBuilderResourceData);
    this.dataSource = formBuilderResourceData.resourcePropertyList;
    this.resourcePropertyTable();
  }

  getResourceTypeDropdown() {
    this._formbuilderService.getResourceTypesList().subscribe({
      next: (response: HttpResponse<any>) => {
        this.resourcetypes = response.body.formBuilderResourceTypesList;
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Resource Types', 2000, 'warn-snackbar');
      }
    });
  }

  formDetailsSubmit() {
    const { resourceType, resourceDisplayName, gitRef, tfStateFolderName, resourceNameFormula, resourceIconUrl, dependsOn } = this.newresourceTemplateForm.value;
    const { formBuilderResourceData } = this.resourceTemplate;
    if (formBuilderResourceData) {
      Object.assign(formBuilderResourceData, { resourceType, resourceDisplayName, gitRef, tfStateFolderName, resourceNameFormula, resourceIconUrl, dependsOn });
    }
  }

  resourcePropertyTable(): void {
    this._formbuilderService.getFormResourcePropertyList().subscribe({
      next: (res: any) => {
        const resourcePropertyList = res.body.resourcePropertyList;
        const formBuilderResourceData = this.resourceTemplate.formBuilderResourceData?.resourcePropertyList;
        this.updatedVariableBankList = resourcePropertyList.filter((p: any) => !formBuilderResourceData?.find(a => p.id === a.id));
        this.formFieldsTableSource = new MatTableDataSource(this.updatedVariableBankList);
        this.formFieldsTableSource.paginator = this.paginator;
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Resource Property List', 2000, 'warn-snackbar');
      }
    });
  }

  addFormFieldsDialog(): void {
    const dialogRef = this._dialog.open(FormBuilderFieldDialogComponent, {
      data: null,
      disableClose: true,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.resourcePropertyTable();
      }, 800);
    });
  }

  editProperty(propertyId: string) {
    const dialogRef = this._dialog.open(FormBuilderFieldDialogComponent, { data: propertyId, width: '600px' });
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log(val);
        this.resourcePropertyTable();
      }
    });
  }

  formFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterExistingFields(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.formFieldsTableSource.filter = filterValue.trim().toLowerCase();

    if (this.formFieldsTableSource.paginator) {
      this.formFieldsTableSource.paginator.firstPage();
    }
  }

  removeFormField(tfvarFormData: any) {
    const resourcePropertyList = this.resourceTemplate.formBuilderResourceData?.resourcePropertyList;
    const fieldIndex = resourcePropertyList?.findIndex((item) => item.id === tfvarFormData.id);

    if (fieldIndex !== undefined && fieldIndex >= 0) {
      resourcePropertyList?.splice(fieldIndex, 1);
      this.resourcePropertyTable();
      this.dataSource = new MatTableDataSource(resourcePropertyList);
    }
  }

  appendField(property: any) {
    this._formbuilderService.getFormBuilderResourceField(property.id).subscribe({
      next: (res: any) => {
        if (this.resourceTemplate.formBuilderResourceData) {
          this.resourceTemplate.formBuilderResourceData.resourcePropertyList.push(
            res.body
          );
        }
        this.resourcePropertyTable();
        this.updateDataSource();
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Resource Field', 2000, 'warn-snackbar');
      }
    });
  }

  private updateDataSource() {
    this.dataSource = new MatTableDataSource(
      this.resourceTemplate.formBuilderResourceData?.resourcePropertyList
    );
  }

  createFormDialogSubmit() {
    this.ResourceForm.push(this.newresourceTemplateForm.value);
  }

  // Testbench code (Needs to be reworked)
  randomizeTicketId() {
    const range = { min: 1000, max: 9999 };
    const buffer = new Uint32Array(1);
    window.crypto.getRandomValues(buffer);
    const randomNumber = buffer[0] % (range.max - range.min + 1) + range.min;
    this.randTicketId = "EAA-" + randomNumber;
  }
  scrollPoint2() {
    this.my_point_2.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  scrollPoint3() {
    this.my_point_3.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    this._formbuilderService
      .postPhytonForCreation(this.currentFormId, this.pyScriptEncoded)
      .subscribe({
        next: (data) => {
          this.scriptResult = data;
        },
        error: (error) => {
          this.scriptResult = error.message;
          this._coreService.openSnackBar('Error while executing Python Script', 2000, 'warn-snackbar');
        }
      });
  }

  scrollPoint4() {
    this.my_point_4.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    this._formbuilderService.postPhytonValidate(this.currentFormId).subscribe({
      next: (data) => {
        this.scriptResult = data.body;
      },
      error: () => {
        //Intentional
      }
    });
  }

  submitEntireForm(stepper: MatStepper) {
    this.prepareDialogData();
    this.encodeResourceIconUrl();
    this.dialogService.confirmDialog(this.dialogData).subscribe((confirmed) => {
      if (confirmed) {
        this.handleFormSubmission(stepper);
      }
    });
  }

  private prepareDialogData() {
    this.dialogData = {
      title: this.counter == 0 ? 'Confirm Action' : 'Continue',
      message: this.counter == 0 ? 'Do you want to Create a form?' : 'Your latest Form changes will be saved',
      confirmCaption: this.counter == 0 ? 'Confirm' : 'Save',
      cancelCaption: 'Cancel',
    };
  }

  private encodeResourceIconUrl() {
    if (this.resourceTemplate.formBuilderResourceData?.resourceIconUrl) {
      const str = this.resourceTemplate.formBuilderResourceData.resourceIconUrl;
      const encodedIconUrl = this.base64Service.encode(str);
      this.resourceTemplate.formBuilderResourceData.resourceIconUrl = encodedIconUrl;
    }
  }

  private handleFormSubmission(stepper: MatStepper) {
    console.log(this.resourceTemplate);
    const formBuilderServiceObservable = this.counter == 0
      ? this._formbuilderService.postFormBuilderResourceForm(this.resourceTemplate)
      : this._formbuilderService.putFormBuilderResourceForm(this.resourceTemplate, this.currentFormId);

    formBuilderServiceObservable.subscribe({
      next: () => {
      this.counter++;
      this._coreService.openSnackBar('Form Saved Successfully', 1000);
      stepper.next();
      //Call this.EmptyBPForm();
      },
      error: () => {
      this._coreService.openSnackBar('Error while saving Form', 2000, 'warn-snackbar');
      }
    });
  }

  // EmptyBPForm() {
  //   this._blueprintService.getEmptySubscriptionTemplate().pipe(
  //     catchError(error => {
  //       this._coreService.openSnackBar('Error while fetching Blueprint', 2000, 'warn-snackbar');
  //       return throwError(error);
  //     })
  //   ).subscribe(
  //     async (res: HttpResponse<any>) => {
  //       this.blueprintJson = res.body;
  //       const userdetailscache = localStorage.getItem('userdetails');
  //       if (userdetailscache) {
  //         const userdetails = JSON.parse(userdetailscache);
  //         this.blueprintJson.userRequestInfo.userId = userdetails.userId;
  //         this.blueprintJson.userRequestInfo.userAADId =
  //           userdetails.userPrincipalName;
  //         this.blueprintJson.userRequestInfo.userAADName = userdetails.userName;
  //       }
  //       this.getFormById();
  //     }
  //   );
  // }

  getFormById() {
    this._blueprintService.getBpResourceForm(this.currentFormId).subscribe({
      next: (response: HttpResponse<any>) => {
        this.isLoading = false;
        response.body.resourceList[0].resourceData.dguid = this.dguid;
        this.formdetails = response.body;
        this.displayName =
          response.body.resourceList[0].resourceData.resourceDisplayName;
        this.resourceTypedisplayName =
          response.body.resourceList[0].resourceData.resourceType;
        this.uguid = response.body.resourceList[0].resourceData.uguid;
        this.currentResourcePropertyList =
          response.body.resourceList[0].resourceData.resourcePropertyList;
        this.inventoryformgroup = this.createInventoryFormGroup();
        this.appendToBpJson();
        this.addSubscriptionDetails();
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Form', 2000, 'warn-snackbar');
      }
    });
  }

  appendToBpJson() {
    const response: any = this.formdetails;
    if (response) {
      this.blueprintJson.resourceInventoryData.push(this.formdetails);
    }
    this.blueprintDataService.setIsVariableSet(true);
    this.uguid = response.resourceList[0].resourceData.uguid;
  }

  createInventoryFormGroup() {
    const formGroup = new FormGroup({});
    const currentResourcePropertyList = this.currentResourcePropertyList;
    for (const currentResource of currentResourcePropertyList) {
      if (currentResource.propertyNameAlias) {
        let formControl;
        if (currentResource.userInput) {
          formControl = new FormControl(
            currentResource.propertyValue,
            Validators.required
          );
        } else {
          if (currentResource.itemList) {
            const itemListArray = currentResource.itemList.split(';');
            currentResource.propertyValue = itemListArray[0];
          }
          formControl = new FormControl(currentResource.propertyValue);
        }
        formGroup.addControl(currentResource.propertyName, formControl);
      }
    }
    return formGroup;
  }

  resourceFormSubmit() {
    const currentFormValues = this.inventoryformgroup.value;
    this.currentResourcePropertyList.forEach((field) => {
      if (field.propertyNameAlias) {
        field.propertyValue = currentFormValues[field.propertyName];
      }
    });
    this._formbuilderService
      .putFormBuilderPyscriptBPjson(this.currentFormId, this.blueprintJson)
      .subscribe({
      next: () => {
        this._coreService.openSnackBar('Form Saved Successfully', 1000);
      },
      error: () => {
        this._coreService.openSnackBar('Error while saving Form', 2000, 'warn-snackbar');
      }
      });
  }

  addSubscriptionDetails() {
    this.blueprintJson.subscriptionData.resourceData.azsid =
      'd42849a0-f922-4cc5-adef-e78facac7da5';
    this.blueprintJson.resourceInventoryData[0].resourceList[0].resourceData.ticketId =
      this.randTicketId;
    this.blueprintJson.subscriptionData.resourceData.resourcePropertyList.forEach(
      (obj: { propertyName: string; propertyValue: string }) => {
        switch (obj.propertyName) {
          case 'applicationFamily':
            obj.propertyValue = 'Platform_Team';
            break;
          case 'ECAD_Cost_Center':
            obj.propertyValue = 'ECAD-002-DCES';
            break;
          case 'env':
            obj.propertyValue = 'dev';
            break;
          case 'subscriptionFullyQualifiedName':
            obj.propertyValue = 'ecad-pt-dev-opssandbox';
            break;
        }
      }
    );
  }
  goBack() {
    this.location.back();
  }

}
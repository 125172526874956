<mat-toolbar *ngIf="title">

  <img style="height: 25px;" [src]="unimatrixLogo" alt="">

  <span class="example-spacer"> </span>
  <a mat-button [routerLink]="'/dashboard'" routerLinkActive="active-link"> Home </a>
  <a mat-button [routerLink]="'/monitoring'" *ngIf="userRoles.grafanaUser" routerLinkActive="active-link"> Monitor </a>
  <a mat-button [routerLink]="'/blueprint/explorer'" *ngIf="userRoles.blueprintView" routerLinkActive="active-link"> Blueprints </a>
  <a mat-button [routerLink]="'/approval'" *ngIf="userRoles.blueprintApproval_L1 || userRoles.blueprintApproval_DevOps" routerLinkActive="active-link"> Approvals </a>
  <a mat-button [routerLink]="'/deployment'" *ngIf="userRoles.adminDevOps" routerLinkActive="active-link"> Deployments </a>
  <!-- <a mat-button [matMenuTriggerFor]="blueprintMenu" #blueprintMenuTrigger="matMenuTrigger"
  (click)="blueprintMenuTrigger.openMenu()" *ngIf="userRoles.blueprintView" routerLinkActive="active-link"> Blueprints </a> -->

  <span class="example-spacer"></span>
  <button mat-raised-button color="accent" style="border-radius: 25px;" [matBadge]="pendingApprovalCount" matBadgeColor="primary" [routerLink]="['/approval']">Pending Approvals</button>
  <div [hidden]="true">
    <mat-slide-toggle #darkModeSwitch color="primary" [checked]="lightTheme" (change)="toggle()"></mat-slide-toggle>
  </div>
  <!-- <button mat-icon-button aria-label="Notifications">
    <i-tabler name="bell"></i-tabler>
  </button> -->
  
  <button mat-icon-button [matMenuTriggerFor]="settingsMenu" #menuTrigger="matMenuTrigger"
    (click)="menuTrigger.openMenu()">
    <mat-icon class="d-flex align-items-center">
      <i-tabler name="settings"></i-tabler>
    </mat-icon>
  </button>

  <!-- <mat-menu #blueprintMenu="matMenu">
    <span>
      <button mat-menu-item *ngIf="userRoles.blueprintView" routerLink="/blueprint/explorer">
        <mat-icon>
          <i-tabler name="folder"></i-tabler>
        </mat-icon>
        <span>Draft Explorer</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.blueprintCreate" routerLink="/blueprint/subscription/new">
        <mat-icon>
          <i-tabler name="circle-plus"></i-tabler>
        </mat-icon>
        <span>New Subscription</span>
      </button>
      <button mat-menu-item routerLink="/approval" *ngIf="userRoles.blueprintApproval_L1 || userRoles.blueprintApproval_DevOps">
        <mat-icon>check_circle_outline</mat-icon>
        <span>Approvals</span>
      </button>
      <button mat-menu-item routerLink="/deployment" *ngIf="userRoles.adminDevOps">
        <mat-icon>history</mat-icon>
        <span>Deployments</span>
      </button>
    </span>
  </mat-menu> -->

  <mat-menu #settingsMenu="matMenu" style="width: max-content;">
    <span>
      <div mat-menu-item  style="justify-content: center;">
        <h3>Hi, {{userDetails.UserName.split(' ')[0]}} !</h3>
      </div>
      <button mat-menu-item *ngIf="userRoles.adminModule" routerLink="/admin">
        <mat-icon>
          <i-tabler name="shield-lock"></i-tabler>
        </mat-icon>
        <span>Admin</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.adminRoleModule"  routerLink="/roles">
        <mat-icon>
          <i-tabler name="users-group"></i-tabler>
        </mat-icon>
        <span>RBAC</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.adminRoleModule" routerLink="/tenants">
        <mat-icon>
          <i-tabler name="template"></i-tabler>
        </mat-icon>
        <span>Tenants</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.formbuilderView" routerLink="/formbuilder">
        <mat-icon>
          <i-tabler name="checklist"></i-tabler>
        </mat-icon>
        <span>Form Builder</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.serviceAccount"  routerLink="/service">
        <mat-icon>
          <i-tabler name="user-shield"></i-tabler>
        </mat-icon>
        <span>Service Account</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>
          <i-tabler name="logout"></i-tabler>
        </mat-icon>
        <span>Logout</span>
      </button>
    </span>
  </mat-menu>
</mat-toolbar>
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DialogService } from 'src/services/confirm-dialog.service';
import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
  constructor(private dialogService: DialogService) {}

  canDeactivate(component: any): Observable<boolean> | boolean {
      if (component.hasUnsavedChanges()) { 
        return this.dialogService.confirmDialog({
          title: "Exit Blueprint",
          message: "Your changes have not been saved. Do you want to Exit?",
          confirmCaption: "Exit",
          cancelCaption: "Cancel",
        });
      }
      else{
        return this.dialogService.confirmDialog({
          title: "Exit Blueprint",
          message: "Your changes have been saved. Do you want to Exit?",
          confirmCaption: "Exit",
          cancelCaption: "Cancel",
        });
      }
  }
}
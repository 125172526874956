<app-navigation>
  <div style="height: calc(100vh - 3rem);">
    <div class="file-explorer-grid">
      <mat-card id="item-0">
        <a mat-fab extended color="primary" routerLink="/blueprint/subscription/new"
          style="border-radius: 10px; height: 35px; margin: 10px 10px 0px 10px;">
          <mat-icon>add</mat-icon>
          New Subscription
        </a>

        <mat-card-header>
          <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="onTabChange($event)" class="mat-tab">
            <mat-tab label="My Drafts"></mat-tab>
            <mat-tab label="Shared" *ngIf="userRoles.blueprintTemplateAdmin"></mat-tab>
          </mat-tab-group>
        </mat-card-header>

        <mat-card-content style="margin-top: 10px;">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Draft Search</mat-label>
            <input matInput minlength="2" (keyup)="applyFilter($any($event.target).value)"
              placeholder="Looking for a Blueprint?" #input maxlength="50" appFormInput>
          </mat-form-field>
        </mat-card-content>

        <mat-tree [dataSource]="draftsListDataSource" [treeControl]="draftsListTreeControl"
          style="height: 100%; overflow-y: scroll;margin-left: 20px;">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="0" matTreeNodeToggle>
            <mat-icon class="type-icon" fontIcon="description" *ngIf="!enableCopy;else elseicon">
            </mat-icon>
            <a [ngClass]="{'highlighted':selectedNodeId===node.dguid}"
              (click)="getBlueprintJson(node.dguid, false, node.subid,node.type)">
              <ng-template #elseicon>
                <mat-icon class="type-icon" svgIcon="subscriptionIcon">
                </mat-icon>
              </ng-template>
              {{node.name}}
            </a>
          </mat-tree-node>

          <mat-nested-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <div class="mat-tree-node">
              <a mat-icon matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{draftsListTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </a>
              <mat-icon class="type-icon" svgIcon="subscriptionIcon"
                *ngIf="node.name!=='Drafts' && node.name!=='InProgress' && node.name!=='Archived'">
              </mat-icon>
              <a [ngClass]="{'highlight': node.name !== 'Drafts' && node.name !== 'InProgress' && node.name !== 'Archived' && selectedNodeId===node.dguid}"
                class="example-spacer"
                (click)="node.name !== 'Drafts' && node.name !== 'InProgress' && node.name !== 'Archived' && getBlueprintJson(node.dguid, true, node.subid, node.type)">
                {{node.name}}
                <sup *ngIf="node.type === 'draft'" class="unimatrix-text-badge">new</sup>
              </a>
            </div>
            <div style="padding-left: 16px;">
              <ul [class.example-tree-invisible]="!draftsListTreeControl.isExpanded(node)"
                style="padding-left:0px; margin-block-start: 0px;">
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </mat-card>
      <div id="item-1" style="position: relative;">
        <mat-spinner *ngIf="!loadBpJson" class="spinner"></mat-spinner>
        <app-blueprint-canvas style="filter: saturate(40%);" *ngIf="loadBpJson"
          [toolbar]="false"></app-blueprint-canvas>
        <div *ngIf="loadBpJson && !blueprintJson" class="error-message">
            <h2 style="color: black;">Sorry, we couldn't load the blueprint selected at the moment.</h2>
        </div>
      </div>
      <mat-card id="item-2">
        <div style="display: grid; padding: 10px;">
          <h3>Details{{isTemplateDraft?': Template Draft':''}}</h3>
          <div style="display: flex; gap: 10px; justify-content: space-between;" *ngIf="blueprintJson">
            <p>Name:
              <mat-chip *ngIf="loadBpJson"> {{subscriptionFullyQualifiedName}} </mat-chip>
            </p>
            <p>Status:
              <mat-chip *ngIf="loadBpJson">{{blueprintJson.userRequestInfo ? blueprintJson.userRequestInfo.state :
                blueprintJson.subscriptionData.resourceData.resourceStatus}}</mat-chip>
            </p>
            <p>Cost:
              <mat-chip *ngIf="loadBpJson"> {{ totalcost | currency: 'EUR' }}</mat-chip>
            </p>
            <p>Created On:
              <mat-chip *ngIf="loadBpJson"> {{(blueprintJson.userRequestInfo ? blueprintJson.userRequestInfo.createdOn :
                blueprintJson.subscriptionData.resourceData.createdOn) | epochToDate}}</mat-chip>
            </p>
          </div>
          <div *ngIf="!blueprintJson && loadBpJson">No details available at the moment.</div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div style="display: grid; padding: 10px; height: 70%; gap:5px;">
          <h3>Actions</h3>
          <div [class.disabled]="!blueprintJson || !loadBpJson" style="display: flex; gap: 10px; justify-content: space-evenly;"
            *ngIf="!enableCopy">
            <div [matTooltip]="userRoles.blueprintTemplateAdmin ? '' : 'User does not have access'" style="width: 100%;">
              <div [matTooltip]="!userRoles.blueprintDelete || true ? 'Cannot archive the draft' : ''"
                style="width: 100%;">
                <button [disabled]="!userRoles.blueprintDelete || true" style="width: 100%;" mat-stroked-button
                  *ngIf="!isTemplateDraft; else elseBlock1">Archive</button>
              </div>
              <ng-template #elseBlock1>
                <button style="width: 100%;" mat-stroked-button color="primary"
                  [disabled]="!userRoles.blueprintTemplateAdmin" (click)="shareTo()" matTooltip="Share this Draft">
                  Share
                </button>
              </ng-template>
            </div>
            <div [matTooltip]="userRoles.blueprintCreate? '' : 'User does not have access to Open'" style="width: 100%;">
  
              <button style="width: 100%;" mat-flat-button color="primary"
                *ngIf="isSub && type === 'fullyQualified'; else elseBlock" [disabled]="!userRoles.blueprintCreate"
                (click)="cloneBlueprint()">Clone</button>
  
              <ng-template #elseBlock>
                <button style="width: 100%;" mat-flat-button color="primary" [disabled]="!userRoles.blueprintCreate"
                  (click)="editBlueprint()" [disabled]="!isEditable">Edit
                </button>
              </ng-template>
  
            </div>
          </div>
          <div [class.disabled]="!blueprintJson" style="display: flex; gap: 10px; justify-content: space-evenly;"
            *ngIf="enableCopy">
            <div [matTooltip]="userRoles.blueprintTemplateAdmin ? '' : 'User does not have access'" style="width: 100%;">
              <div [matTooltip]="!userRoles.blueprintDelete || true ? 'Cannot archive the draft' : ''"
                style="width: 100%;">
                <button [disabled]="!userRoles.blueprintDelete || true" style="width: 100%;" mat-stroked-button
                  *ngIf="!isSharedUser; else elseBlock1">Archive</button>
              </div>
              <ng-template #elseBlock1>
                <button style="width: 100%;" mat-stroked-button color="primary"
                  [disabled]="!userRoles.blueprintTemplateAdmin" (click)="shareTo()" matTooltip="Share this Draft">
                  Share
                </button>
              </ng-template>
            </div>
            <div [matTooltip]="userRoles.blueprintCreate? '' : 'User does not have access to Open'" style="width: 100%;">
  
              <button style="width: 100%;" mat-flat-button color="primary" *ngIf="!isSharedUser; else elseBlock"
                [disabled]="!userRoles.blueprintCreate" (click)="cloneBlueprint()">Copy</button>
  
              <ng-template #elseBlock>
                <button style="width: 100%;" mat-flat-button color="primary" [disabled]="!userRoles.blueprintCreate"
                  (click)="editBlueprint()" [disabled]="!isEditable">Edit
                </button>
              </ng-template>
  
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</app-navigation>
import { Injectable } from '@angular/core';

interface ResourceNameOptions {
  count: number;
  subscriptionIdentifier: string;
  env: string;
  identifier: string;
  appFam: string;
  applicationFamily: string;
  subscriptionName: string;
}

@Injectable({
  providedIn: 'root'
})

export class ResourceNameService {

  getResourceName(resourceName: string, options: ResourceNameOptions) {
    const { count, subscriptionIdentifier, env, identifier, appFam, applicationFamily, subscriptionName } = options;
    const formattedCount = count < 10 ? '0' + count : count.toString();
    return resourceName.replace(/{indexNo}/, formattedCount)
      .replace(/{subscriptionIdentifier}/, subscriptionIdentifier)
      .replace(/{env}/, env)
      .replace(/{identifier}/, identifier)
      .replace(/{appFam}/, appFam)
      .replace(/{applicationFamily}/, applicationFamily)
      .replace(/{subscriptionName}/, subscriptionName);
  }

  getLastElements(array: [], count: number) {
    return array.slice(Math.max(array.length - count, 0));
  }
}
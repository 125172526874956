import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/services/navigation.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BlueprintsService } from 'src/services/blueprints.service';
import { BlueprintDataService } from 'src/services/blueprint-data.service';
import { Router } from '@angular/router';
import { UsersubscriptionService } from 'src/services/usersubscription.service';
import { MatDialog } from '@angular/material/dialog';
import { BlueprintCloneDialogComponent } from 'src/app/components/dialog/blueprint-clone-dialog/blueprint-clone-dialog.component';
import { AesencryptionService } from 'src/services/encryption.service';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';
import { RoleData } from '../../roles-table/roles';
import { DialogService } from 'src/services/confirm-dialog.service';
import { DraftTemplateActivationDialogComponent } from 'src/app/components/dialog/draft-template-activation-dialog/draft-template-activation-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface TreeNode {
  expandable: boolean;
  dguid: string;
  subid: string;
  type: string;
  name: string;
  children?: any[];
}

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss'],
})

export class FileExplorerComponent implements OnInit {
  selectedNodeId = '';
  draftsListTreeControl = new NestedTreeControl<TreeNode>(
    (node) => node.children
  );
  draftsListDataSource = new MatTreeNestedDataSource<TreeNode>();
  userRoles: RoleData;
  loadBpJson = false;
  blueprintJson: any;
  subscriptionFullyQualifiedName: string | undefined;
  totalcost!: number;
  dguid!: string;
  isSub!: boolean;
  subid = '';
  type = '';
  isTemplateDraft?: boolean;
  isTemplateActive?: boolean;
  shareToRoleIds: any;
  enableCopy?: boolean;
  currentTab: string = 'Draft Explorer';
  isSharedUser?: boolean;
  isEditable: any;
  searchString: string = '';
  firstNode: any;
  treeData: any;
  constructor(
    private navigationService: NavigationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private _blueprintService: BlueprintsService,
    private router: Router,
    private blueprintDataService: BlueprintDataService,
    private usersubscription: UsersubscriptionService,
    public dialog: MatDialog,
    private aesencryptionService: AesencryptionService,
    private coreService: CoreService,
    private usersubscriptionService: UsersubscriptionService,
    private dialogService: DialogService,
    private _coreService: CoreService,
    private _dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      'subscriptionIcon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/images/logos/Subscriptions.svg'
      )
    );
    this.userRoles = JSON.parse(
      aesencryptionService.decryptUsingAES256(
        sessionStorage.getItem('userRoles') ?? ''
      )
    );
  }

  ngOnInit() {
    this.navigationService.setTitle('Draft Explorer');
    this.getBPdraftList();
  }

  transformData(data: any[]): TreeNode[] {
    if (!Array.isArray(data)) {
      console.error('Data is not an array:', data);
      return [];
    }
    const createChildNode = (name: string, items: any[] | null) => {
      if (!items || items.filter((item) => item !== null).length === 0) {
        return null;
      }
      return {
        name,
        children: items.filter((item) => item !== null).map((item) => ({
          name: item.name,
          dguid: item.dguid,
        })),
        dguid: '',
        subid: '',
        type: '',
        expandable: true,
      };
    };
    return data
      .filter((item) => item !== null)
      .map((item) => {
        const node: TreeNode = {
          name: item.name,
          children: [
            createChildNode('InProgress', item.inProgress),
            createChildNode('Drafts', item.drafts),
            createChildNode('Archived', item.Archived),
          ].filter((child) => child !== null),
          dguid: item.dguid,
          subid: item.subid,
          type: item.type,
          expandable: true,
        };
        return node;
      });
  }

  getBPdraftList() {
    this.enableCopy = false;
    this.usersubscription.getSubscriptionList(false).subscribe(
      (res: any) => {
        let sub = true;
        this.draftsListDataSource.data = this.transformData(res.body.value);
        this.treeData = this.draftsListDataSource.data;
        this.draftsListTreeControl.dataNodes = this.draftsListDataSource.data;
        const selectedDguid = this.blueprintDataService.getDguid();
        this.blueprintDataService.setDguid('');
        if (selectedDguid) {
          this.firstNode = this.findNodeByDguid(
            this.draftsListDataSource.data,
            selectedDguid
          );
          sub = false;
        } else {
          this.firstNode = this.draftsListDataSource.data[0];
          sub = true;
        }
        this.getBlueprintJson(
          this.firstNode.dguid,
          sub,
          this.firstNode.subid,
          this.firstNode.type
        );
        this.draftsListTreeControl.expandAll();
      },
      () =>
        this.coreService.openSnackBar(
          'Error while fetching Blueprint',
          2000,
          'warn-snackbar'
        )
    );
  }

  getSharedTemplates() {
    this.enableCopy = true;
    this.usersubscription.getRemoteSubscriptionTemplateList().subscribe(
      (res: any) => {
        let sub = true;
        this.draftsListDataSource.data = res.body;
        this.treeData = this.draftsListDataSource.data;
        const selectedDguid = this.blueprintDataService.getDguid();
        if (selectedDguid) {
          this.firstNode = this.findNodeByDguid(
            this.draftsListDataSource.data,
            selectedDguid
          );
          sub = false;
        } else {
          this.firstNode = this.draftsListDataSource.data[0];
          sub = true;
        }
        this.getBlueprintJson(this.firstNode.dguid, sub, '', 'template');
      },
      (error) => this.coreService.openSnackBar(error, 3000, 'warn-snackbar')
      //this.coreService.openSnackBar('Error while fetching Blueprint', 2000, 'warn-snackbar')
    );
  }

  findNodeByDguid(nodes: any[], dguid: string): any {
    for (const node of nodes) {
      if (node.dguid === dguid) {
        return node;
      }
      if (node.children) {
        const childNode = this.findNodeByDguid(node.children, dguid);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  }

  hasChild = (_: number, node: TreeNode) => node.expandable;

  shouldShowNode(node: TreeNode): boolean {
    if (!this.searchString) {
      return true;
    }
    return (
      new RegExp(this.searchString, 'i').test(node.name) ||
      this.nodeHasMatchingChild(node)
    );
  }

  nodeHasMatchingChild(node: TreeNode): boolean {
    if (!node.children) {
      return false;
    }
    return node.children.some((child) => this.shouldShowNode(child));
  }
  
  filterRecursive(filterText: string, array: any[], property: string) {
    let filteredData;
    //make a copy of the data so we don't mutate the original
    function copy(o: any) {
      return Object.assign({}, o);
    }
    if (filterText) {
      // need the string to match the property value
      filterText = filterText.toLowerCase();
      // copy obj so we don't mutate it and filter
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        // if children match
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
      // no string, return whole array
    } else {
      filteredData = array;
    }
    return filteredData;
  }

  // pass mat input string to recursive function and return data
  filterTree(filterText: string) {
    // use filter input text, return filtered TREE_DATA, use the 'name' object value
    this.draftsListDataSource.data = this.filterRecursive(
      filterText,
      this.treeData,
      'name'
    );
    this.draftsListTreeControl.dataNodes = this.draftsListDataSource.data;
  }

  // filter string from mat input filter
  applyFilter(filterText: string) {
    this.filterTree(filterText);
    // show / hide based on state of filter string
    if (filterText) {
      this.draftsListTreeControl.expandAll();
    } else {
      this.draftsListTreeControl.collapseAll();
    }
  }

  editBlueprint() {
    this.router.navigate(['/blueprint/edit', this.dguid]);
  }

  getBlueprintJson(dguid: string, sub: boolean, subid: string, type: string) {
    this.loadBpJson=false;
    this.isSub = sub;
    this.dguid = dguid;
    this.subid = subid;
    this.type = type;
    this.selectedNodeId = dguid;
    if (
      this.isSub === true &&
      this.type !== 'draft' &&
      this.type !== 'template'
    ) {
      this.usersubscriptionService.getFQSubscriptionBlueprint(dguid).subscribe(
        (response: HttpResponse<any>) =>
          this.handleBlueprintJsonResponse(response),
        () => {
          this.coreService.openSnackBar(
            'Error while fetching Blueprint',
            2000,
            'warn-snackbar'
          );
          this.loadBpJson = true;
        }
      );
    } else {
      this._blueprintService.getBpUserDraftJson(dguid).subscribe(
        (response: HttpResponse<any>) => {
          this.handleBlueprintJsonResponse(response);
          this.isEditable = response.body.userRequestInfo.isEditable;
        },
        () => {
          this.coreService.openSnackBar(
            'Error while fetching Blueprint',
            2000,
            'warn-snackbar'
          );
          this.loadBpJson = true;
        }
      );
    }
  }

  cloneBlueprint() {
    this.dialog.open(BlueprintCloneDialogComponent, {
      width: '400px',
      data: { subid: this.subid, isCopy: this.enableCopy, dguid: this.dguid },
    });
  }

  private handleBlueprintJsonResponse(response: HttpResponse<any>) {
    this.isSharedUser = false;
    this.isTemplateDraft = false;
    this.blueprintJson = response.body;
    this.shareBlueprintData(this.blueprintJson);
    this.subscriptionFullyQualifiedName =
      this.blueprintJson.subscriptionData.resourceData.resourcePropertyList.find(
        (property: { propertyName: string }) =>
          property.propertyName === 'subscriptionFullyQualifiedName'
      ).propertyValue;
    this.loadBpJson = true;
    let totalcost = parseFloat(
      this.blueprintJson?.subscriptionData?.resourceData.currentCost
    );
    this.blueprintJson?.resourceInventoryData.forEach((resource: any) => {
      resource.resourceList.forEach((item: any) => {
        totalcost = totalcost + parseFloat(item.resourceData.currentCost);
      });
    });
    this.totalcost = totalcost;
    if (this.blueprintJson.userRequestInfo.state === 'template') {
      this.isTemplateDraft = true;
    }
    if (this.blueprintJson.userRequestInfo.isCreator) {
      this.isSharedUser = true;
    }
  }

  shareBlueprintData(blueprintJsonData: any) {
    this.blueprintDataService.setBlueprintData(blueprintJsonData);
  }

  shareTo(): void {
    this._blueprintService
      .getActivationStatus(this.dguid)
      .subscribe((response: HttpResponse<any>) => {
        this.shareToRoleIds = response.body.ShareToRoles;
        this.isTemplateActive = response.body.IsActive;
        const data = {
          shareToRoleIds: this.shareToRoleIds,
          isTemplateActive: this.isTemplateActive,
        };
        const dialogRef = this._dialog.open(
          DraftTemplateActivationDialogComponent,
          {
            data,
            width: '400px',
          }
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.postActivationStatus(
              result.sharedUserRolesId,
              result.isActive
            );
          }
        });
      });
  }

  postActivationStatus(RoleIds: any, activationStatus: boolean) {
    this._blueprintService
      .postActivationStatus(this.dguid, activationStatus, RoleIds)
      .subscribe(
        (response: HttpResponse<any>) => {
          const message = response.body;
          this._coreService.openSnackBar(message, 5000, 'success-snackbar');
        },
        (error: HttpErrorResponse) => {
          this._coreService.openSnackBar(
            'Error Sharing the Template',
            2000,
            'warn-snackbar'
          );
        }
      );
  }
  
  onTabChange(event: MatTabChangeEvent) {
    this.currentTab = event.tab.textLabel;
    if (this.currentTab == 'My Drafts') {
      this.getBPdraftList();
    } else {
      this.getSharedTemplates();
    }
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlueprintsService } from 'src/services/blueprints.service';
import { HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BlueprintDataService } from 'src/services/blueprint-data.service';
import { CoreService } from '../../shared/snackbar/snackbar.service';
export interface Infracost {
  item: string;
  cost: string;
}
@Component({
  selector: 'app-blueprint-resource-form-dialog',
  templateUrl: './blueprint-resource-form-dialog.component.html',
  styleUrls: ['./blueprint-resource-form-dialog.component.scss']
})
export class BlueprintResourceFormDialogComponent implements OnInit{

  blueprintJson: any;
  uguid = "";
  currentResourcePropertyList: any[] = [];
  resourceFormGroup: any = FormGroup;
  displayName = "";
  resourceCount = 0;
  loader = true;
  resourceForm: any;
  isFormSubmitting = false;

  constructor(
    private _blueprintService: BlueprintsService,
    private blueprintDataService: BlueprintDataService,
    private coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<BlueprintResourceFormDialogComponent>) { }

  ngOnInit() {
    this.blueprintJson = this.data.bpJson;
    this.getFormById(this.data.formId, this.data.dguid);
  }

  handleSuccessfulResponse(response: HttpResponse<any>, dguid: string) {
    this.resourceForm = response.body;
    this.resourceForm.resourceList[0].resourceData.dguid = dguid;
    this.displayName = this.resourceForm.resourceList[0].resourceData.resourceDisplayName;
    this.uguid = this.resourceForm.resourceList[0].resourceData.uguid;
    this.currentResourcePropertyList = this.resourceForm.resourceList[0].resourceData.resourcePropertyList;
    this.resourceFormGroup = this.createInventoryFormGroup();
    this.resourceCount += 1;
    this.loader = false;
  }

  getFormById(Formid: string, dguid: string) {
    this._blueprintService.getBpResourceForm(Formid).subscribe(
      (response: HttpResponse<any>) => {
        this.handleSuccessfulResponse(response, dguid);
      },
      () => {
        this.coreService.openSnackBar('Error in fetching Resource Form', 1000, 'warn-snackbar');
        this.loader = false;
      }
    );
  }

  createInventoryFormGroup() {
    const formGroup = new FormGroup({});
    for (const property of this.resourceForm.resourceList[0].resourceData.resourcePropertyList) {
      if (property.propertyNameAlias) {
        const formControl = this.createFormControl(property);
        formGroup.addControl(property.propertyName, formControl);
      }
    }
    return formGroup;
  }

  createFormControl(property: any): FormControl {
    if (property.userInput) {
      return new FormControl(property.propertyValue, Validators.required);
    } else {
      if (property.itemList) {
        const itemListArray = property.itemList.split(';');
        property.propertyValue = itemListArray[0];
      }
      return new FormControl(property.propertyValue);
    }
  }

  resourceFormSubmit() {
    this.isFormSubmitting = true
    this.updateResourceListWithFormValues();
  }

  updateResourceListWithFormValues() {
    this.updateResourceValues();
    this.postResourceForm();
  }

  updateResourceValues() {
    const currentFormValues = this.resourceFormGroup.value;
    this.resourceForm.resourceList[0].resourceData.resourcePropertyList.forEach((field: any) => {
      if (field.propertyNameAlias) {
        field.propertyValue = currentFormValues[field.propertyName];
      }
    });
  }

  postResourceForm() {
    const resourcePropertyList =  this.resourceForm.resourceList[0].resourceData.resourcePropertyList
    this._blueprintService.postBPResourceForm(resourcePropertyList, this.data.formId, this.data.dguid, this.uguid)
    .subscribe({
      next: () => {
        this.updateBlueprintJson();
        this.coreService.openSnackBar('Form Saved Successfully', 2000);
      },
      error: () => {
        this.coreService.openSnackBar('Error while saving the resource form', 2000, 'warn-snackbar');
      }
    }
    );
  }

  updateBlueprintJson() {
    if (this.resourceForm) {
      const resourceType = this.resourceForm.resourceType;
      let resourceTypeExists = false;
      for (const resource of this.blueprintJson.resourceInventoryData) {
        if (resource.resourceType === resourceType) {
          resource.resourceList.push(this.resourceForm.resourceList[0]);
          resourceTypeExists = true;
          break;
        }
      }
      if (!resourceTypeExists) {
        this.blueprintJson.resourceInventoryData.push(
          this.resourceForm
        );
      }
    }
    this.shareBlueprintData(this.blueprintJson);
    this.closeDialogWithData();
  }

  shareBlueprintData(blueprintJsonData: any) {
    this.blueprintDataService.setBlueprintData(blueprintJsonData);
  }

  closeDialogWithData() {
    this.dialogRef.close(this.uguid);
  }
}

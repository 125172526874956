import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { MonitoringComponent } from './components/pages/monitoring/monitoring.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { BrowserUtils } from '@azure/msal-browser';
import { FormbuilderComponent } from './components/pages/formbuilder/formbuilder.component';
import { PublishFormComponent } from './components/pages/admin/admin.component';
import { CallbackComponent } from './components/pages/callback/callback.component';
import { BlueprintComponent } from './components/pages/blueprint/blueprint.component';
import { BlueprintNewSubscriptionComponent } from './components/pages/blueprint/blueprint-new-subscription/blueprint-new-subscription.component';
import { FileExplorerComponent } from './components/pages/blueprint/blueprint-explorer/file-explorer.component';
import { RolesTableComponent } from './components/pages/roles-table/roles-table.component';
import { ServiceAccountComponent } from './components/pages/service-account/service-account.component';
import { ResourceFormsComponent } from './components/pages/formbuilder/resource-forms/resource-forms.component';
import { ApprovalComponent } from './components/pages/approval/approval.component';
import { ApprovalReviewComponent } from './components/pages/approval/approval-review/approval-review.component';
import { DeploymentComponent } from './components/pages/deployment/deployment.component';
import { TenantsCatalogueComponent } from './components/pages/tenants-catalogue/tenants-catalogue.component';
import { CanDeactivateGuard } from './canDeactivate.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: CallbackComponent},
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: PublishFormComponent , canActivate: [AuthGuard]},
  { path: 'blueprint/explorer', component: FileExplorerComponent, canActivate: [AuthGuard] },
  { path: 'blueprint/edit/:id', component:BlueprintComponent, canActivate:[AuthGuard],canDeactivate:[CanDeactivateGuard] },
  { path: 'blueprint/subscription/new', component:BlueprintNewSubscriptionComponent, canActivate:[AuthGuard] },
  { path: 'formbuilder', component: FormbuilderComponent, canActivate: [AuthGuard] },
  { path: 'formbuilder/new', component: ResourceFormsComponent, canActivate:[AuthGuard] },
  { path: 'formbuilder/edit/:id', component: ResourceFormsComponent, canActivate: [AuthGuard] },
  { path: 'roles', component: RolesTableComponent, canActivate: [AuthGuard] },
  { path: 'service', component: ServiceAccountComponent, canActivate: [AuthGuard] },
  { path: 'approval', component: ApprovalComponent, canActivate: [AuthGuard] },
  { path: 'deployment', component: DeploymentComponent, canActivate: [AuthGuard] },
  { path: 'review/:id', component: ApprovalReviewComponent, canActivate: [AuthGuard] },
  { path: 'tenants', component: TenantsCatalogueComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
<app-navigation>
    <main>
        <h2>Tenants Catalogue</h2>
        <section style="margin-bottom: 1rem;">
            <mat-card class="resource-table">
                <mat-card-header>
                    <mat-card-title>Tenant Catalogue</mat-card-title>
                    <mat-card-subtitle>Manage the forms for each tenant</mat-card-subtitle>
                    <div style="margin-left: auto;">
                        <a color="primary" mat-fab extended (click)="openAddTenantDialog()">
                            <mat-icon>add</mat-icon>
                            New Tenant
                        </a>
                    </div>
                </mat-card-header>
                <mat-card-content style="margin-top: 10px;">
                    <div class="tenant-table-container">
                        <div *ngIf="tenantDataSource.data.length === 0">
                            <h3>No Tenants Found, Please Add a Tenant</h3>
                        </div>
                        <table mat-table *ngIf="tenantDataSource.data.length> 0" [dataSource]="tenantDataSource"
                            class="mat-elevation-z2" style="filter: brightness(110%);">
                            <ng-container matColumnDef="Name" sticky>
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element"> {{ element.tenantName }} </td>
                            </ng-container>

                            <ng-container *ngFor="let column of tenantDisplayedColumns">
                                <ng-container *ngIf="column !== 'Name' && column !== 'Actions'">
                                    <ng-container [matColumnDef]="column">
                                        <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
                                        <td mat-cell *matCellDef="let element" class="center-checkbox">
                                            <ng-container *ngFor="let form of element.formList">
                                                <ng-container *ngIf="form.resourceDisplayName === column">
                                                    <mat-checkbox [(ngModel)]="form.isActive"
                                                        [disabled]="isCheckboxDisabled(element)"></mat-checkbox>
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <!-- Actions Column -->
                            <ng-container matColumnDef="Actions" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button *ngIf="!isSaveButtonVisible(element)"
                                        (click)="editRow(element)" [disabled]="isEditButtonDisabled(element)">
                                        <i-tabler name="edit"></i-tabler>
                                    </button>
                                    <button mat-icon-button *ngIf="isSaveButtonVisible(element)"
                                        (click)="saveRow(element)">
                                        <i-tabler name="circle-check"></i-tabler>
                                    </button>
                                    <button mat-icon-button *ngIf="isSaveButtonVisible(element)" (click)="discardRow()">
                                        <i-tabler name="circle-x"></i-tabler>
                                    </button>
                                </td>
                            </ng-container>

                            <!-- Header and Row definitions -->
                            <tr mat-header-row *matHeaderRowDef="tenantDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: tenantDisplayedColumns;"></tr>
                        </table>
                    </div>

                </mat-card-content>
            </mat-card>
        </section>
    </main>
</app-navigation>
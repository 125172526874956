<app-navigation>
  <main>
    <h2>Deployments</h2>
    <section style="margin-bottom: 2rem;">
          <div class="example-container mat-elevation-z8">  
            <mat-form-field style="width: 100%;" appearance="outline">
              <input matInput (keyup)="applyFilter($event)" placeholder="Search">
            </mat-form-field>
            <div class="example-loading-shade"
                 *ngIf="isLoadingResults || isRateLimitReached">
              <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
              <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
                We are sorry, but we cannot show any results at the moment.
              </div>
            </div>
            <mat-card>
              <!-- <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end" (selectedTabChange)="onTabChange($event)"
                style="flex: 1;">
                <mat-tab label="Current"></mat-tab>
                <mat-tab label="Queued"></mat-tab>
                <mat-tab label="History"></mat-tab>
              </mat-tab-group> -->
              <mat-card-content>
                <div class="example-table-container">
          
              <table mat-table [dataSource]="data" class="example-table" matSort matSortActive="createdOn" matSortDisableClear
                matSortDirection="desc">
                <ng-container matColumnDef="summary">
                  <th mat-header-cell *matHeaderCellDef>Summary</th>
                  <td mat-cell *matCellDef="let row">{{row.summary ? row.summary : 'No Summary Added'}}</td>
                </ng-container>

                <ng-container matColumnDef="draftName">
                  <th mat-header-cell *matHeaderCellDef>Draft Name</th>
                  <td mat-cell *matCellDef="let row">
                    <a [routerLink]="['/review', row.dguid]" style="color: #00c853; text-decoration: none;">{{row.draftName}}</a>
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="subscriptionName">
                  <th mat-header-cell *matHeaderCellDef>Subscription</th>
                  <td mat-cell *matCellDef="let row">{{row.subscriptionName}}</td>
                </ng-container>
              
                <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Requester</th>
                  <td mat-cell *matCellDef="let row">{{row.userName}}</td>
                </ng-container>
              
                <ng-container matColumnDef="subStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                  <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.subStatus==='Failed' || row.subStatus==='PartiallyFailed'; else statusBlock">
                      <button mat-button [disabled]="!userRoles.blueprintCreate"
                        [routerLink]="['/blueprint/edit',row.dguid]" [style.color]="'#f44336 !important'" [style.background-color]="'rgba(244, 67, 54, 0.1)'">{{row.subStatus}}
                      </button>
                    </ng-container>
                    <ng-template #statusBlock>
                      {{row.subStatus}}
                    </ng-template>
                  </td>
              
                </ng-container>
              
                <ng-container matColumnDef="createdOn">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                    Created
                  </th>
                  <td mat-cell *matCellDef="let row">{{row.createdOn | epochToDate}}</td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </mat-card-content>
            <mat-card-footer>
            <mat-paginator [length]="resultsLength" [pageSize]="30" aria-label="Select page of GitHub search results"></mat-paginator>
          </mat-card-footer>
          </mat-card>
          </div>
    </section>
  </main>
</app-navigation>
<mat-toolbar *ngIf="title">
  <!-- Hamburger Icon for Small Screens -->
  <button mat-icon-button [matMenuTriggerFor]="hamburgerMenu" #hamburgerTrigger="matMenuTrigger" (click)="hamburgerTrigger.openMenu()" class="hamburger-icon">
    <mat-icon>menu</mat-icon>
  </button>

  <img style="height: 25px;" [src]="unimatrixLogo" alt="">

  <span class="example-spacer"> </span>
  <div class="nav-links">
  <a mat-button [routerLink]="'/dashboard'" routerLinkActive="active-link"> Dashboard </a>
  <a mat-button [routerLink]="'/monitoring'" *ngIf="userRoles.grafanaUser" routerLinkActive="active-link"> Monitor </a>
  <a mat-button [routerLink]="'/blueprint/explorer'" *ngIf="userRoles.blueprintView" routerLinkActive="active-link"> Blueprints </a>
  <a mat-button [routerLink]="'/approval'" *ngIf="userRoles.blueprintApproval_L1 || userRoles.blueprintApproval_DevOps" routerLinkActive="active-link"> Approvals </a>
  <a mat-button [routerLink]="'/deployment'" *ngIf="userRoles.adminDevOps" routerLinkActive="active-link"> Deployments </a>
</div>

  <span class="example-spacer"></span>
  <button mat-raised-button color="accent" style="border-radius: 25px; font-weight: 400;margin-right: 1rem;"
    [matBadge]="pendingApprovalCount" matBadgeColor="primary" [routerLink]="['/approval']">Pending Approvals</button>
  <div [hidden]="true">
    <mat-slide-toggle #darkModeSwitch color="primary" [checked]="lightTheme" (change)="toggle()"></mat-slide-toggle>
  </div>

  <button mat-icon-button [routerLink]="'/landing'">
    <mat-icon class="d-flex align-items-center">
      <i-tabler name="info-hexagon"></i-tabler>
    </mat-icon>
  </button>
  
  <button mat-icon-button [matMenuTriggerFor]="settingsMenu" #menuTrigger="matMenuTrigger"
    (click)="menuTrigger.openMenu()">
    <mat-icon class="d-flex align-items-center">
      <i-tabler name="settings"></i-tabler>
    </mat-icon>
  </button>

  <mat-menu #settingsMenu="matMenu" style="width: max-content;">
    <span>
      <button mat-menu-item>
        <mat-icon>
          <i-tabler name="user-circle"></i-tabler>
        </mat-icon>
        <span>Hi, {{userDetails.UserName.split(' ')[0]}}</span>
      </button>

      <button mat-menu-item *ngIf="userRoles.adminModule" routerLink="/admin">
        <mat-icon>
          <i-tabler name="shield-lock"></i-tabler>
        </mat-icon>
        <span>Admin</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.adminRoleModule"  routerLink="/roles">
        <mat-icon>
          <i-tabler name="users-group"></i-tabler>
        </mat-icon>
        <span>RBAC</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.adminRoleModule" routerLink="/tenants">
        <mat-icon>
          <i-tabler name="category-plus"></i-tabler>
        </mat-icon>
        <span>Tenants</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.formbuilderView" routerLink="/formbuilder">
        <mat-icon>
          <i-tabler name="checklist"></i-tabler>
        </mat-icon>
        <span>Form Builder</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.serviceAccount"  routerLink="/service">
        <mat-icon>
          <i-tabler name="api"></i-tabler>
        </mat-icon>
        <span>Service Account</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>
          <i-tabler name="logout"></i-tabler>
        </mat-icon>
        <span>Logout</span>
      </button>
    </span>
  </mat-menu>

 <div class="hamburgerMenu-container">
  <mat-menu #hamburgerMenu="matMenu">
    <span>
      <button mat-menu-item  routerLink="/dashboard">
        <span>Dashboard</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.grafanaUser" routerLink="/monitoring">
        <span>Monitoring</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.blueprintView" routerLink="/blueprint/explorer">
        <span>Blueprints</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.blueprintApproval_L1 || userRoles.blueprintApproval_DevOps"  routerLink="/approval">
        <span>Approvals</span>
      </button>
      <button mat-menu-item *ngIf="userRoles.adminDevOps" routerLink="/deployment">
        <span>Deployments</span>
      </button>
    </span>
  </mat-menu>
</div>
</mat-toolbar>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmptyResourceTemplate, FormBuilderResourceData, FormsList, ResourcePropertyList } from 'src/app/models/empty-resource-template';
@Injectable({
  providedIn: 'root'
})
export class FormbuilderService {

  GET_FORM_LIST_API_URL = environment.API_URL + 'FormBuilderResourceFormList';
  GET_RESOURCE_TYPE_LIST_API_URL = environment.API_URL + 'FormBuilderResourceTypesList';
  GET_EMPTY_RESOURCE_TEMPLATE_API_URL = environment.API_URL + 'FormBuilderResourceFormTemplate';
  GET_FORM_RESOURCE_PROPERTY_LIST_URL = environment.API_URL + 'FormBuilderResourcePropertyList';
  GET_APP_FAMILY_LIST_API_URL = environment.API_URL + 'AdminAppFamily';
  POST_APP_FAMILY_URL = environment.API_URL + 'AdminAppFamily';
  POST_FORMBUILDER_FORM_URL = environment.API_URL + 'FormBuilderResourceForm';
  PUT_FORMBUILDER_FORM_URL = environment.API_URL + 'FormBuilderResourceForm';
  GET_FORMBUILDER_AZURE_RESOURCETYPE_URL = environment.API_URL + 'FormBuilderAzureResourceTypesList';
  PUT_FORMBUILDER_RESOURCETYPE_URL = environment.API_URL + 'FormBuilderResourceType';
  POST_FORMBUILDER_RESOURCETYPE_URL = environment.API_URL + 'FormBuilderResourceType';
  GET_FORMBUILDER_RESOURCETYPE_URL = environment.API_URL + 'FormBuilderResourceType';
  POST_FORM_RESOURCE_PROPERTY_URL = environment.API_URL + 'FormBuilderResourceProperty';
  PUT_FORM_RESOURCE_PROPERTY_URL = environment.API_URL + 'FormBuilderResourceProperty';
  PUT_FORMBUILDER_PUBLISH_FORM_URL = environment.API_URL + 'FormBuilderPublishForm';
  GET_FORMBUILDER_RESOURCE_VARIABLES_URL = environment.API_URL + 'FormBuilderVariablesList';
  GET_EXISTING_RESOURCE_TEMPLATE_URL = environment.API_URL + 'FormBuilderResourceForm';
  PUT_FORMBUILDER_PYSCRIPT_BPJson_URL = environment.API_URL + 'PyScriptBPJson';
  POST_FORMBUILDER_PY_CREATION_URL = "https://localhost:44301/PhythonVerification";
  POST_FORMBUILDER_PY_VALIDATE_URL = "https://localhost:44301/PhythonVerification/validate";

  constructor(private http: HttpClient) {
  }

  public getFormsList() {  //Done
    return this.http
    .get<FormsList[]>(this.GET_FORM_LIST_API_URL, { observe: 'response' })
    .pipe<FormsList[]>(map((data: object) => data as FormsList[]));
  }

  public getEmptyResourceTemplate(): Observable<EmptyResourceTemplate[]> { //Done
    return this.http
    .get<EmptyResourceTemplate[]>(this.GET_EMPTY_RESOURCE_TEMPLATE_API_URL, { observe: 'response' })
    .pipe<EmptyResourceTemplate[]>(map((data: object) => data as EmptyResourceTemplate[]));
  }

  public getResourceTypesList(): Observable<HttpResponse<string>> {   //Done
    const requestOptions: object = {
      observe: 'response',
      //responseType: 'text'
    }
    return this.http.get<HttpResponse<string>>(this.GET_RESOURCE_TYPE_LIST_API_URL, requestOptions);
  }

  public getAppFamilyList() {   //Done
    return this.http.get(this.GET_APP_FAMILY_LIST_API_URL).pipe(map((data: object) => data));
  }
  
  public postAppFamily(appfamilydata: any) {
    const params = new HttpParams()
    .set('appFamilyName', appfamilydata.value.appFamilyName)
    .set('isGrafanaOrgRequired', appfamilydata.value.grafanaId);
    return this.http.post(this.POST_APP_FAMILY_URL, null, { params, observe: 'response' });
  }

  getFormResourcePropertyList(): Observable<ResourcePropertyList[]> {
    return this.http
    .get<ResourcePropertyList[]>(this.GET_FORM_RESOURCE_PROPERTY_LIST_URL, { observe: 'response' })
    .pipe<ResourcePropertyList[]>(map((data: object) => data as ResourcePropertyList[]));
  }

  postFormBuilderResourceForm(formresponse: object){
    return this.http.post(this.POST_FORMBUILDER_FORM_URL, formresponse, { observe: 'response' });
  }

  putFormBuilderResourceForm(formresponse: EmptyResourceTemplate, FormId: string) {
    const params = new HttpParams()
    .set('FormId', FormId);
    return this.http.put(this.PUT_FORMBUILDER_FORM_URL, formresponse, { params, observe: 'response' });
  }

  public getFormBuilderAzureResourceTypesList(): Observable<HttpResponse<string>> {

    return this.http.get<string>(this.GET_FORMBUILDER_AZURE_RESOURCETYPE_URL, { observe: 'response' });
  }

  
  public postFormBuilderResourceType(resourcedata: object) {
    return this.http.post(this.POST_FORMBUILDER_RESOURCETYPE_URL, resourcedata, { observe: 'response' });
  }

  public putFormBuilderResourceType(resourcedata: object) {
    return this.http.put(this.PUT_FORMBUILDER_RESOURCETYPE_URL, resourcedata, { observe: 'response'});
  }

  public putFormBuilderPublishForm(FormId: string, Published: boolean) {
    const params = new HttpParams()
      .set('FormId', FormId)
      .set('Published', Published.toString()); // Convert boolean to string
    return this.http.put(this.PUT_FORMBUILDER_PUBLISH_FORM_URL, null, { params, observe: 'response' });
  }

  public putFormBuilderPyscriptBPjson(FormId:string, resourcedata: object){
    const params = new HttpParams()
    .set('FormId', FormId);
    return this.http.put(this.PUT_FORMBUILDER_PYSCRIPT_BPJson_URL, resourcedata, { params, observe: 'response'});
  }

  public getFormBuilderResourceField(PropertyId: string): Observable<HttpResponse<FormBuilderResourceData>> {
    const params = new HttpParams()
    .set('PropertyId', PropertyId);
    return this.http.get<FormBuilderResourceData>(this.POST_FORM_RESOURCE_PROPERTY_URL, { params, observe: 'response' })
  }

  public postFormBuilderResourceField(resourcedata: FormBuilderResourceData[]): Observable<HttpResponse<FormBuilderResourceData>> {
    return this.http.post<FormBuilderResourceData>(this.POST_FORM_RESOURCE_PROPERTY_URL, resourcedata, { observe: 'response' })
  }

  public putFormBuilderResourceField(resourcedata: FormBuilderResourceData): Observable<HttpResponse<FormBuilderResourceData>> {
    return this.http.put<FormBuilderResourceData>(this.PUT_FORM_RESOURCE_PROPERTY_URL, resourcedata, { observe: 'response' })
  }

  public getFormBuilderResourceType(resourceType: string): Observable<HttpResponse<string>> {
    const params = new HttpParams()
      .set('ResourceType', resourceType);
    return this.http.get<string>(this.GET_FORMBUILDER_RESOURCETYPE_URL, { params, observe: 'response' });
  }

  public getResourceVariables() {
    return this.http.get(this.GET_FORMBUILDER_RESOURCE_VARIABLES_URL, { observe: 'response' })
  }

  getExistingResourceTemplate(FormId:string): Observable<FormBuilderResourceData[]> {
    const params = new HttpParams()
    .set('FormId', FormId)
    return this.http
    .get<FormBuilderResourceData[]>(this.GET_EXISTING_RESOURCE_TEMPLATE_URL, { params, observe: 'response' })
    .pipe<FormBuilderResourceData[]>(map((data: object) => data as FormBuilderResourceData[]));
  }

  //OLD CODE
  public postPhytonForCreation(guid: string, scriptData: any): Observable<any> {    
    const body = {"Formid":guid, "EncodedData":scriptData}
    return this.http.post(this.POST_FORMBUILDER_PY_CREATION_URL,body );
  }

  public postPhytonValidate(guid: string): Observable<any> {    

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const params = new HttpParams()
    .set('guid', guid)   
    return this.http.post(this.POST_FORMBUILDER_PY_VALIDATE_URL,null,{headers,params, observe: 'response' } );
  }
}
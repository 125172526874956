<section style="display: flex; justify-content: center; width: 100%;">
  <div class="container">
    <div #chartContainer id="chartContainer" style="background-color: rgba(255, 255, 255);"></div>
  </div>

  <mat-card class="toolbar" *ngIf="toolbar">
    <button style="margin: 0 5px;" mat-mini-fab color="primary" (click)="bpCostSubmit()" matTooltip="Cost">
      <i-tabler name="cloud-dollar" class="toolbar-icon spinner rotate"></i-tabler>
    </button>
    <button mat-icon-button (click)="shareTo()" matTooltip="Share Draft"
      *ngIf="userRoles.blueprintTemplateAdmin && isTemplate">
      <i-tabler name="share" class="toolbar-icon"></i-tabler>
    </button>

    <button mat-icon-button (click)="expandAll()" matTooltip="Expand All">
      <i-tabler name="arrows-maximize" class="toolbar-icon"></i-tabler>
    </button>

    <button *ngIf="userRoles.adminDevOps" mat-icon-button [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu">
      <i-tabler name="file-download" class="toolbar-icon"></i-tabler>
    </button>

    <mat-slide-toggle *ngIf="!userRoles.adminDevOps" [(ngModel)]="isChecked" (click)="compact()"></mat-slide-toggle>

    <input type="text" placeholder="Resource Search" (input)="filterChart($event)" />

    <button *ngIf="userRoles.adminDevOps && bpJsonCopy.userRequestInfo.state==='Deployments - Failed' || bpJsonCopy.userRequestInfo.state==='Deployments - PartiallyFailed'" mat-icon-button
      matTooltip="Retrigger" [matTooltipPosition]="'above'" (click)="openRetrigger()">
      <mat-icon>
        <i-tabler name="alert-circle" class="toolbar-icon" style="color: red;"></i-tabler>
      </mat-icon>
    </button>
    <button mat-icon-button matTooltip="ZoomIn" [matTooltipPosition]="'above'" (click)="zoomIn()">
      <mat-icon>
        <i-tabler name="zoom-in" class="toolbar-icon"></i-tabler>
      </mat-icon>
    </button>

    <button mat-icon-button matTooltip="ZoomOut" [matTooltipPosition]="'above'" (click)="zoomOut()">
      <mat-icon>
        <i-tabler name="zoom-out" class="toolbar-icon"></i-tabler>
      </mat-icon>
    </button>

    <button mat-icon-button (click)="exit()" matTooltip="Exit">
      <i-tabler name="door-exit" class="toolbar-icon"></i-tabler>
    </button>
  </mat-card>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="exportImg()">
      <mat-icon>
        <i-tabler name="file-type-png"></i-tabler>
      </mat-icon>
      <span>PNG</span>
    </button>
    <button mat-menu-item (click)="exportMySvg()">
      <mat-icon>
        <i-tabler name="file-type-svg"></i-tabler>
      </mat-icon>
      <span>SVG</span>
    </button>
    <button mat-menu-item (click)="downloadPdf()">
      <mat-icon>
        <i-tabler name="file-type-pdf"></i-tabler>
      </mat-icon>
      <span>PDF</span>
    </button>
  </mat-menu>
</section>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { HttpResponse } from "@angular/common/http";
import { CoreService } from "../../shared/snackbar/snackbar.service";
import { MatDialog } from '@angular/material/dialog';
import { AesencryptionService } from 'src/services/encryption.service';
import { NavigationService } from 'src/services/navigation.service';
import { RoleData } from '../roles-table/roles';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApprovalCommentDialogComponent } from '../../dialog/approval-comment-dialog/approval-comment-dialog.component';
import { ApprovalsService } from './approvals.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  userRequestTableColumns = ['draftSummary', 'subscriptionName', 'requestorName','status', 'date'];
  userRequestTableDataSource!: MatTableDataSource<any>;
  loadTable = false;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('userRequestTablePaginator') userRequestTablePaginator!: MatPaginator;
  userRoles: RoleData;
  filterValue!: string;
  approvalList: any;
  selectedTab!: string;

  constructor(
    private _approvalService: ApprovalsService,
    private _coreService: CoreService,
    public dialog: MatDialog,
    private aesencryptionService: AesencryptionService,
    private navigationService: NavigationService,
  ) {
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngOnInit() {
    this.bpApprovalData();
    this.navigationService.setTitle('Approvals');
  }

  bpApprovalData(): void {
    this._approvalService.getBpApprovalList().subscribe({
      next: (res: HttpResponse<any>) => {
        this.approvalList=res.body;
        this.approvalList.sort((a: { requestDate: string[]; }, b: { requestDate: string[]; }) => {
          const dateA = parseInt(a.requestDate[0], 10);
          const dateB = parseInt(b.requestDate[0], 10);
          return dateB - dateA; // Sort in descending order
        });
        this.userRequestTableDataSource = new MatTableDataSource(this.approvalList);
        const pendingApprovalsCount = this.userRequestTableDataSource.data.filter(item => item.isApprover).length;
        const encryptedPendingApprovalsCount=this.aesencryptionService.encryptUsingAES256(pendingApprovalsCount)
        sessionStorage.setItem('pendingRequestsCount', encryptedPendingApprovalsCount);
        this.onTabChange({ tab: { textLabel: 'Pending' } } as MatTabChangeEvent);
        this.userRequestTableDataSource.paginator = this.userRequestTablePaginator;
        this.loadTable = true;
      },
      error: () => {
        this._coreService.openSnackBar('Error in fetching Approval List', 1000, 'warn-snackbar');
        this.loadTable = true;
      }
    });
  }

  changeApprovalStatus(status: string,dguid: string) {
    const dialogRef = this.dialog.open(ApprovalCommentDialogComponent, { width: '400px' });
    dialogRef.afterClosed().subscribe((comment: any) => {
      if (comment) {
        this.loadTable = false;
        const message = status === 'Approved' ? 'Approved Blueprint' : 'Rejected Blueprint';
        this._approvalService
          .postApproval(dguid, status, comment)
          .subscribe({
            next: () => {
              this._coreService.openSnackBar(message, 1000);
              this.bpApprovalData();
            },
            error: () => {
              this._coreService.openSnackBar('Error in Rejecting Blueprint', 1000, 'warn-snackbar');
              this.loadTable = true;
            }
          });
      }
    });
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel;
    this.applyFilters();
  }

  applyFilters() {
    let filteredData: any[] = [];
    if(this.filterValue){
    const searchValue = this.filterValue.toLowerCase();
    filteredData = this.approvalList.filter((item: { [key: string]: any; currentStatus: string; isApprover: boolean; archive: boolean;requestDate:any }) => {
      const matchesTab = this.selectedTab === 'Pending' ? (item.currentStatus === 'Pending' || item.currentStatus === 'InProgress' && item.isApprover === true) : (item.currentStatus === 'Approved' || item.currentStatus === 'Rejected' && item.archive === true);
      const matchesSearch = Object.keys(item).some(key => {
        if (typeof item[key] === 'string') {
          return item[key].toLowerCase().includes(searchValue) && (this.selectedTab === 'Pending' ? item.isApprover === true : item.archive === true);
        }
        return false;
      });
      return matchesTab && matchesSearch;
    });
  }
  else{
    filteredData = this.approvalList.filter((item: { currentStatus: string; isApprover: boolean; archive: boolean}) => {
      const matchesTab = this.selectedTab === 'Pending' ? ((item.currentStatus === 'Pending' || item.currentStatus === 'InProgress') && item.isApprover === true) : ((item.currentStatus === 'Approved' || item.currentStatus === 'Rejected') && item.archive === true);
      return matchesTab;
    });
  }
    this.userRequestTableDataSource.data = filteredData;
    this.userRequestTableDataSource.paginator = this.userRequestTablePaginator;
    this.userRequestTableDataSource.sort = this.sort;
  }

  approvalsListFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.applyFilters();
  }

}
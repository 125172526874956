import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApprovalCommentDialogComponent } from 'src/app/components/dialog/approval-comment-dialog/approval-comment-dialog.component';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';
import { Location } from '@angular/common';
import { NavigationService } from 'src/services/navigation.service';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ResourceDataService } from 'src/services/resource-data.service';
import { ApprovalsService } from '../approvals.service';

@Component({
  selector: 'app-approval-review',
  templateUrl: './approval-review.component.html',
  styleUrls: ['./approval-review.component.scss']
})
export class ApprovalReviewComponent {
  showViewButton!: boolean;
  resourceDetailsTableColumns: string[] = ['resourceName', 'resourceType', 'cost'];
  dguid: string = "";
  loadTable: boolean = false;
  subscriptionName: any;
  approvalHistoryDataSource = new MatTableDataSource<any>();
  totalCurrentCost = 0;
  totalPreviousCost = 0;
  tables = [
    {
      title: 'Subscription',
      subtitle: 'Below are the subscriptions to be ',
      dataSource: new MatTableDataSource<any>(),
    },
    {
      title: 'Created Resources',
      subtitle: 'Below are the resources to be Created',
      dataSource: new MatTableDataSource<any>(),
      columns: ['resourceName', 'resourceType', 'cost']
    },
    {
      title: 'Updated Resources',
      subtitle: 'Below are the resources to be Updated',
      dataSource: new MatTableDataSource<any>(),
      columns: ['resourceName', 'resourceType', 'cost']
    },
    {
      title: 'Deleted Resources',
      subtitle: 'Below are the resources to be Deleted',
      dataSource: new MatTableDataSource<any>(),
      columns: ['resourceName', 'resourceType', 'cost']
    },
    {
      title: 'Synced Resources',
      subtitle: 'Below are the resources to be Synced',
      dataSource: new MatTableDataSource<any>(),
      columns: ['resourceName', 'resourceType', 'cost']
    }
  ];
  isApprover: any;
  summary: any;
  draftName: any;
  isNewSubRequest: any;
  draftCreatedDate: any;
  subscriptionFullyQualifiedName: any;
  subUserActionType: any;

  constructor(
    public dialog: MatDialog,
    private _approvalService: ApprovalsService,
    private _coreService: CoreService,
    private location: Location,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private resourceDataService: ResourceDataService,
  ) { }

  ngOnInit() {
    this.navigationService.setTitle('Review Page');
    this.dguid = this.activatedRoute.snapshot.params['id'];
    this.getApproverStatus(this.dguid);
    this.getBlueprintReviewDetails(this.dguid);
    this.getApprovalHistory(this.dguid);
  }

  getBlueprintReviewDetails(dguid: string) {
    this._approvalService.getBpReviewJson(dguid).subscribe({
      next: (response: HttpResponse<any>) => {
        if(response.body==="New subscription request"){
          this.isNewSubRequest=response.body
        }
        else{
        const blueprintJson = response.body;
        let totalPreviousCost = blueprintJson.subscriptionData.resourceData.previousCost;
        let totalCurrentCost = blueprintJson.subscriptionData.resourceData.currentCost;
        blueprintJson.resourceInventoryData.forEach((resourceGroup: { resourceList: any[]; }) => {
          totalPreviousCost += resourceGroup.resourceList.reduce((sum, resource) => sum + (resource.resourceData.userActionType === "" ? resource.resourceData.currentCost : resource.resourceData.previousCost), 0);
          totalCurrentCost += resourceGroup.resourceList.reduce((sum, resource) => sum + resource.resourceData.currentCost, 0);
        });
        this.totalCurrentCost = totalCurrentCost;
        this.totalPreviousCost = totalPreviousCost;
        const result = this.resourceDataService.bpReviewData(blueprintJson);
        this.subscriptionFullyQualifiedName =
        blueprintJson.subscriptionData.resourceData.resourcePropertyList.find(
              (property: { propertyName: string }) =>
                property.propertyName === 'subscriptionFullyQualifiedName'
            ).propertyValue;
        blueprintJson.subscriptionData.resourceData.resourceName = this.subscriptionFullyQualifiedName;
        this.subUserActionType = blueprintJson.subscriptionData.resourceData.userActionType
        if (this.subUserActionType !== "") {
            this.tables[0].dataSource.data.push(blueprintJson.subscriptionData.resourceData);
          }
        this.tables[1].dataSource.data = result.created;
        this.tables[2].dataSource.data = result.updated;
        this.tables[3].dataSource.data = result.deleted;
        this.tables[4].dataSource.data = result.synced;
        if(!this.isApprover){
          this.tables[0].subtitle='Below are the subscriptions '+(this.tables[0].title==='Subscription' ? this.subUserActionType +'d' : '');
          this.tables[1].subtitle='Below are the resources created'
          this.tables[2].subtitle='Below are the resources updated'
          this.tables[3].subtitle='Below are the resources deleted'
          this.tables[4].subtitle='Below are the resources synced'
        }
      }
      this.loadTable = true;
      },
      error: () => {
        this.loadTable = true;
        this._coreService.openSnackBar('Error while fetching Blueprint', 2000, 'warn-snackbar');
      }
    });
  }
  getApprovalHistory(dguid: string) {
    this._approvalService.getBpApprovalStatus(dguid).subscribe({
      next: (response: HttpResponse<any>) => {
        const approvalHistory = response.body;
        this.approvalHistoryDataSource.data = approvalHistory;
      },
      error: () => {
        this.loadTable = true;
        this._coreService.openSnackBar('Error while fetching Blueprint History', 2000, 'warn-snackbar');
      }
    });
  }
  getApproverStatus(dguid: string) {
    this._approvalService.getBpApproverStatus(dguid).subscribe({
      next: (response: HttpResponse<any>) => {
        const draftDetails = response.body[0];
        this.draftName = draftDetails.draftName;
        this.summary = draftDetails.summary
        this.subscriptionName = draftDetails.subscriptionName;
        this.draftCreatedDate=draftDetails.requestDate;
        this.isApprover = draftDetails.isApprover;
      },
      error: () => {
        this.loadTable = true;
        this._coreService.openSnackBar('Error while fetching Blueprint History', 2000, 'warn-snackbar');
      }
    });
  }


  changeApprovalStatus(status: string) {
    const dialogRef = this.dialog.open(ApprovalCommentDialogComponent, { width: '400px' });
    dialogRef.afterClosed().subscribe((comment: any) => {
      if (comment) {
        const message = status === 'Approved' ? 'Approved Blueprint' : 'Rejected Blueprint';
        this._approvalService
          .postApproval(this.dguid, status, comment)
          .subscribe({
            next: () => {
              this._coreService.openSnackBar(message, 1000);
              this.loadTable = true;
              this.location.back();
            },
            error: () => {
              this._coreService.openSnackBar('Error in Blueprint Approval', 1000, 'warn-snackbar');
              this.loadTable = true;
            }
          });
      }
    });
  }
  goBack() {
    this.location.back();
  }

  getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }
}

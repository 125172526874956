import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup} from "@angular/forms";
import { MAT_DIALOG_DATA} from "@angular/material/dialog";
import { FormbuilderService } from "src/services/formbuilder.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CoreService } from "src/app/components/shared/snackbar/snackbar.service";
import { HttpResponse } from "@angular/common/http";
import { Base64Service } from "src/services/base64.service";

@Component({
  selector: "app-formbuilder-resource-type-dialog",
  templateUrl: "./formbuilder-resource-type-dialog.component.html",
  styleUrls: ["./formbuilder-resource-type-dialog.component.scss"],
})
export class ResourceTypeDialogComponent implements OnInit{
  resTypeFormGroup: FormGroup;
  resourcetypearray: any;
  disablefield = true;
  filteredOptions: Observable<string[]> | undefined;
  resourceTypecontrol = new FormControl("");
  options: string[] = [];

  constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formbuilderService: FormbuilderService,
    private _coreService: CoreService,
    private base64Service: Base64Service,
  ) {
    this.resTypeFormGroup = this._fb.group({
      resourceType: "",
      resourceGeneralDisplayName: "",
      resourceGeneralIconUrl: "",
    });
  }

  ngOnInit() {
    this.getAzureResourceTypes();
    if (this.data) {
      this.data.resourceGeneralIconUrl = this.base64Service.decode(this.data.resourceGeneralIconUrl);
      this.resTypeFormGroup.patchValue(this.data);
      const resourceTypeControl = this.resTypeFormGroup.get('resourceType');
      if (resourceTypeControl) {
      resourceTypeControl.disable();
      }
    }
  }

  filterOptions(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getAzureResourceTypes() {
    this._formbuilderService.getFormBuilderAzureResourceTypesList().subscribe(
      (response:HttpResponse<any>)=>{
        this.resourcetypearray = response.body.formBuilderResourceTypesList;
          for(const resource of this.resourcetypearray){
          this.options.push(resource.resourceType);
        }

        this.filteredOptions = this.resourceTypecontrol.valueChanges.pipe(
          startWith(""),
          map((value) => this.filterOptions(value || ""))
        );
      },
      () => {
        this._coreService.openSnackBar('Error Fetching Azure Resource Types', 5000);
      }
    )
  }

  onFormSubmit() {
    if (this.resTypeFormGroup.valid) {
      this.resTypeFormGroup.value.resourceGeneralIconUrl = this.base64Service.encode(this.resTypeFormGroup.value.resourceGeneralIconUrl);
      if (this.data) {
        const body = {
          resourceType: this.data.resourceType,
          resourceGeneralDisplayName:
            this.resTypeFormGroup.value.resourceGeneralDisplayName,
          resourceGeneralIconUrl:
            this.resTypeFormGroup.value.resourceGeneralIconUrl,
        };
        
        this._formbuilderService.putFormBuilderResourceType(body).subscribe(
          () => {
            this._coreService.openSnackBar('Updated Successfully!', 1000);
          },
          () => {
            this._coreService.openSnackBar('Error Updating Resource Type', 5000);
          }
        )
      }
      else {
        this.resTypeFormGroup.value.resourceType = this.resourceTypecontrol.value;
        this._coreService.openSnackBar('Added Resource Type', 1000);
        this._formbuilderService.postFormBuilderResourceType(this.resTypeFormGroup.value).subscribe(
          () => {
            this._coreService.openSnackBar('Added Successfully!', 1000);
          },
          () => {
            this._coreService.openSnackBar('Error Adding Resource Type', 5000);
          }
        );
      }
    }
  }

}
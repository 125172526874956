import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlueprintCanvasService {

  constructor() { }

  expandAll(chart: any) {
    chart.expandAll().fit()
  }
}

import { Component, inject, Injectable, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { UsersubscriptionService } from "src/services/usersubscription.service";
import { NavigationService } from "src/services/navigation.service";
import { environment } from "src/environments/environment";
import { HttpResponse } from "@angular/common/http";
import { CoreService } from "../../shared/snackbar/snackbar.service";
import { AesencryptionService } from "src/services/encryption.service";
import { catchError } from "rxjs";
import { BlueprintCloneDialogComponent } from 'src/app/components/dialog/blueprint-clone-dialog/blueprint-clone-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { BlueprintDataService } from "src/services/blueprint-data.service";
import { RoleData } from "../roles-table/roles";
import { BlueprintsService } from "src/services/blueprints.service";
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { UnimatrixStepOption } from './dashboard.tour';
import { ApprovalsService } from "../approval/approvals.service";
import { DeploymentsService } from "../deployment/deployments.service";
import { ApprovalCommentDialogComponent } from "../../dialog/approval-comment-dialog/approval-comment-dialog.component";

export interface Element {
  name: string;
  id: string;
}
export enum SelectType {
  single,
  multiple
}

@Component({
  selector: 'app-resourcedashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class DashboardComponent implements OnInit {
  grafanaDomain = environment.GRAFANA_DOMAIN_URL;
  dashboardOverviewId = environment.GRAFANA_OVERVIEW_ID;
  dashboardOverviewName = environment.GRAFANA_OVERVIEW_NAME;
  azureDataSrc = environment.GRAFANA_AZ_DATA_SRC;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('newsubDraftpaginator') newsubDraftpaginator!: MatPaginator;

  resourceFormsDataSource!: MatTableDataSource<any>;
  resourceFormsColumns: string[] = ['draftName', 'edit', 'archive'];
  ismainLoading = false;
  //table select initialization
  loadTable = false;

  @ViewChild('subscriptionPaginator') subscriptionPaginator!: MatPaginator;
  @ViewChild('draftPaginator') draftPaginator!: MatPaginator;
  @ViewChild('resourceDetailsPaginator') resourceDetailsPaginator!: MatPaginator;
  view = [700, 400];

  showM1 = false;
  showM2 = false;
  ApproverId = '';
  userRoles: RoleData;
  dataSource: any;
  selectType = [
    { text: 'Single', value: SelectType.single },
    { text: 'Multiple', value: SelectType.multiple },
  ];

  selection = new SelectionModel<any>(true, []);
  displayType = SelectType.single;
  displayedColumns: string[] = ['select', 'name', 'cost'];
  // existingdisplayedColumns: string[] = ['name', 'edit', 'archive'];
  existingdisplayedColumns: string[] = ['draftName', 'status'];
  resourceDetailsColumns: string[] = ['resource', 'cost']
  existingDraftTable!: MatTableDataSource<any>;
  resourceDetailsTable!: MatTableDataSource<any>;
  subdata: any;
  loadDraftTable = false;
  loadApprovalTable = false;
  resourceOverview: any;
  resourceDetails: any;
  subid = "";
  type = "";
  loadBpJson = false;
  resourceTypeName = "";
  disableClone = false;
  customColors!: { name: any; value: string; }[];

  private readonly tourService = inject(TourService);
  approvalDrafts: any;
  deploymentsDrafts: any;
  userDetails: any;

  constructor(
    private usersubscription: UsersubscriptionService,
    private navigationService: NavigationService,
    private _coreService: CoreService,
    private aesencryptionService: AesencryptionService,
    public dialog: MatDialog,
    private usersubscriptionService: UsersubscriptionService,
    private blueprintDataService: BlueprintDataService,
    private blueprintService: BlueprintsService,
    private _deploymentService: DeploymentsService,
    private _approvalService: ApprovalsService,
  ) {
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
    this.userDetails = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userdetails')) || '');
  }

  ngOnInit() {
    this.getDraftsStatusTable();
    this.navigationService.setTitle('Data');
    this.ismainLoading = false;
    this.getSubscriptionsAndSetTable();
    this.resourceDetailsTable = new MatTableDataSource();
    this.tourService.initialize(UnimatrixStepOption, {
      disablePageScrolling: true,
      backdropConfig: {
        backgroundColor: 'rgba(0,0,0,0.3)',
      },
    });
    //this.startTour();
  }

  startTour() {
    this.tourService.start();
  }

  selectHandler(row: any) {
    if (this.displayType == SelectType.single) {
      if (!this.selection.isSelected(row)) {
        this.selection.clear();
      }
    }
    this.selection.toggle(row);
    this.getBlueprintJson(row.dguid, row.type);
    // this.existingDraftTable = new MatTableDataSource<any>(this.selection.selected[0].drafts);
    this.subid = row.subid;
    this.type = row.type;
    this._coreService.openSnackBar(
      'Selected ' + this.selection.selected[0].name,
      1000,
      'success-snackbar'
    );
  }

  onChange(typeValue: number) {
    this.displayType = typeValue;
    this.selection.clear();
  }

  onResourceDetailSelect(event: any) {
    this.resourceTypeName = event.name
    const selectedResource = this.resourceDetails.find((resource: any) => resource.resourceGeneralDisplayName === event.name);
    const resourceDataList = selectedResource?.resourceList?.map((resourceData: any) => resourceData.resourceData) || [];
    resourceDataList.sort((a: any, b: any) => b.currentCost - a.currentCost);
    this.resourceDetailsTable = new MatTableDataSource(resourceDataList);
    this.resourceDetailsTable.paginator = this.resourceDetailsPaginator;
    this.customColors = [
      {
        name: event.name,
        value: 'aqua'
      }
    ];
  }

  getSubscriptionsAndSetTable(): void {
    this.loadTable = false;
    this.usersubscription.getSubscriptionList(false).pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error fetching subscriptions', 2000, 'warn-snackbar');
        return (error);
      })
    ).subscribe((res: any) => {
      this.subdata = res.body.value;
      this.subdata.sort((a: any, b: any) => b.cost - a.cost);
      this.dataSource = new MatTableDataSource<any>(this.subdata);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.subscriptionPaginator;
      if (this.displayType === SelectType.single && this.subdata.length > 0) {
        this.selection.select(this.subdata[0]);
        // this.existingDraftTable = new MatTableDataSource<any>(this.selection.selected[0].drafts);   
        const firstDguid = this.subdata[0].dguid;
        this.subid = this.subdata[0].subid;
        this.type = this.subdata[0].type;
        this.getBlueprintJson(firstDguid, this.type); //Temporaily Disabled
      }
    });
    this.loadTable = true;
  }

  getDraftsStatusTable() {
    this._approvalService.getBpApprovalList().pipe(
      catchError(error => {
        this._coreService.openSnackBar('Error fetching drafts status', 2000, 'warn-snackbar');
        return (error);
      })
    ).subscribe((res: any) => {
      res.body.reverse();
      this.approvalDrafts = res.body.filter((draft: { isApprover: any; requesterName: string;currentPendingLevel:any }) => 
        draft.isApprover || (draft.requesterName === this.userDetails.UserName && draft.currentPendingLevel>0)
      );
      // console.log("Approval Drafts: ",this.approvalDrafts);

      this._deploymentService.getBpDeploymentsList().pipe(
        catchError(error => {
          this._coreService.openSnackBar('Error fetching drafts status', 2000, 'warn-snackbar');
          return (error);
        })
      ).subscribe((res: any) => {
        res.body.reverse();
        this.deploymentsDrafts = res.body.filter((draft: { userName: any; }) => draft.userName === this.userDetails.UserName);
        // console.log("Deployments drafts: ",this.deploymentsDrafts);

        const combinedDrafts = [...this.approvalDrafts, ...this.deploymentsDrafts];
        this.existingDraftTable = new MatTableDataSource<any>(combinedDrafts);
        this.existingDraftTable.paginator = this.draftPaginator;
        this.existingDraftTable.data = this.existingDraftTable.data.sort((a, b) => b.isApprover - a.isApprover);
        const pendingApprovalsCount = this.existingDraftTable.data.filter(item => item.isApprover).length;
        const encryptedPendingApprovalsCount=this.aesencryptionService.encryptUsingAES256(pendingApprovalsCount)
        sessionStorage.setItem('pendingRequestsCount', encryptedPendingApprovalsCount);
        this.loadTable = true;
      });
    });
  }
  getBlueprintJson(dguid: string, type: string) {
    if (type == "draft") {
      this.disableClone = true;
      this.blueprintService.getBpUserDraftJson(dguid).subscribe({
        next: (response: HttpResponse<any>) => {
          this.shareBlueprintData(response.body)
          this.getResourceInfo(response.body);
        },
        error: () => this._coreService.openSnackBar('Error fetching blueprint json', 2000, 'warn-snackbar')
      });
    }
    else {
      this.disableClone = false;
      this.usersubscriptionService.getFQSubscriptionBlueprint(dguid).subscribe({
        next: (response: HttpResponse<any>) => {
          this.getResourceInfo(response.body);
          this.shareBlueprintData(response.body)
        },
        error: () => this._coreService.openSnackBar('Error fetching blueprint json', 2000, 'warn-snackbar')
      });
    }
  }

  getResourceInfo(blueprintJsonData: any) {
    const result = blueprintJsonData.resourceInventoryData.map((resource: any) => ({
      name: resource.resourceGeneralDisplayName,
      value: resource.resourceList.length
    }));
    this.resourceOverview = result;
    this.resourceDetails = blueprintJsonData.resourceInventoryData
    const firstResource = this.resourceOverview[0];
    this.onResourceDetailSelect(firstResource);
  }

  shareBlueprintData(blueprintJsonData: any) {
    this.blueprintDataService.setBlueprintData(blueprintJsonData);
    this.loadBpJson = true;
  }

  subscriptionListFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  draftFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.existingDraftTable.filter = filterValue.trim().toLowerCase();
  }
  resourceListFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resourceDetailsTable.filter = filterValue.trim().toLowerCase();
  }
  cloneBlueprint() {
    this.dialog.open(BlueprintCloneDialogComponent, { width: '400px', data: { subid: this.subid } });
  }
  changeApprovalStatus(status: string,dguid: string) {
    const dialogRef = this.dialog.open(ApprovalCommentDialogComponent, { width: '400px' });
    dialogRef.afterClosed().subscribe((comment: any) => {
      if (comment) {
        const message = status === 'Approved' ? 'Approved Blueprint' : 'Rejected Blueprint';
        this._approvalService
          .postApproval(dguid, status, comment)
          .subscribe({
            next: () => {
              this._coreService.openSnackBar(message, 1000);
              this.loadTable = true;
              this.getDraftsStatusTable();
            },
            error: () => {
              this._coreService.openSnackBar('Error in Blueprint Approval', 1000, 'warn-snackbar');
              this.loadTable = true;
              this.getDraftsStatusTable();
            }
          });
      }
    });
  }
}

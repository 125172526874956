<app-navigation>
<mat-drawer-container style="background-color: white; height: calc(100vh - 3rem);" autosize>
	<mat-drawer [opened]="cataloguePanel" mode="side" class="sidebar" tourAnchor="resource-pallet-wlk-thru">
    <div class="sticky-container">
    <div class="drawer-header">
      <span>CATALOGUE</span>
      <button style="position: absolute; right: 5px;" mat-icon-button matTooltip="Collapse Panel" (click)="openCatalogue(false)">
        <i-tabler name="layout-sidebar-left-collapse"></i-tabler>
      </button>
    </div>
    <mat-divider></mat-divider>
    <mat-form-field appearance="outline" style="width: 90%; padding: 0 5%;">
      <mat-label>Resource Search</mat-label>
      <input matInput #userName (input)="iconPanelSearch(userName.value)" [disabled]="!loadBpJson"
        placeholder="Virtual Machine" maxlength="50" appFormInput>
      </mat-form-field>
    </div>
    <div class="scroll-container">
    <mat-accordion [class.disabled]="!loadBpJson" [multi]="false">
      <ng-container *ngFor="let i of filteredList">
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            {{ i.resourceGeneralDisplayName }}
          </mat-expansion-panel-header>
          <div class="resource-icons-grid">
            <div class="resource-icons-container" *ngFor="let j of i.formsList">
               <div class="resource-icons" id={{j.id}} matTooltip="{{ j.resourceDisplayName }}"  [draggable]="userRoles.blueprintCreate" [innerHTML]="j.resourceIconUrl| sanitizeHtml" (dragstart)="drag($event);" (click)="drag($event);"></div>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
	</mat-drawer>

  <mat-drawer-content (drop)="drop($event);" (dragover)="allowDrop($event)" style="overflow: hidden;">
    <mat-spinner *ngIf="!loadBpJson" class="spinner"></mat-spinner>
    <app-blueprint-canvas *ngIf="loadBpJson" [toolbar]="enableToolbar" (shareResourceDataEvent)="selectResourceNode($event)"></app-blueprint-canvas>
    
    <mat-card *ngIf="!cataloguePanel" class="example-box" cdkDragBoundary=".canvas-container" cdkDrag>
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <button style="position: absolute; right: 5px;" mat-mini-fab color="primary" matTooltip="Expand Panel" (click)="openCatalogue(true)">
          <i-tabler name="layout-sidebar-left-expand"></i-tabler>
        </button>
    </mat-card>
  </mat-drawer-content>

  <mat-drawer #drawer mode="over" position="end"  class="inventoryViewer" >
    <ng-container *ngIf="sideMenuContext === 'configurator'">
      <div class="sticky-container">
      <div class="drawer-header">
        <span>RESOURCE CONFIGURATOR</span>
      </div>
      </div>
      <div class="scroll-container">
      <section class="form-grid">
        <mat-divider></mat-divider>
        <mat-expansion-panel expanded="true" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Forms
            </mat-panel-title>
            <mat-icon style="color: rgb(173, 173, 173);">assignment</mat-icon>
          </mat-expansion-panel-header>
  
          <mat-card style="filter: brightness(108%);">
  
          <div *ngIf="currentResourcePropertyList.length>0">
            <mat-card-header>
              <mat-card-subtitle>{{ resourceType }}</mat-card-subtitle>
              <mat-card-title>{{ displayName }}</mat-card-title>
            </mat-card-header>
            <mat-card-content style="margin: 20px 0 0 0;">
  
            <form [formGroup]="resourceFormGroup">
              <div *ngFor="let field of currentResourcePropertyList">
                <div *ngIf="field.propertyNameAlias">
                  <div *ngIf="field.itemList">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{field.propertyNameAlias}}</mat-label>
                      <mat-select [formControlName]="field.propertyName">
                        <mat-option *ngFor="let option of field.itemList.split(';')" [value]="option">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                      <mat-error>This is a mandatory Field</mat-error>
                    </mat-form-field>
                  </div>
    
                  <div *ngIf="!field.itemList">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{field.propertyNameAlias}}</mat-label>
                      <input matInput type="text" [value]="field.propertyValue" [formControlName]="field.propertyName" maxlength="50" appFormInput [disabled]="!field.editable">
                      <mat-error>This is a mandatory Field</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <mat-card-actions align="end">
  
              <div style="display: flex; justify-content: flex-end;">
                <div [matTooltip]="userRoles.blueprintDelete ? isSubscription() ? 'Delete Subscription' : 'Delete Resource' : 'No Permission to Delete Resources'">
                  <button mat-button type="submit" color="warn" [disabled]="!userRoles.blueprintDelete || disableUnmanaged()"
                  (click)="deleteResource(uguid)">Delete
                </button>
                </div>
                <div [matTooltip]="userRoles.blueprintModify ? isSubscription() ? 'Cannot Update Subscription' :'Update Resource' : 'No Permission to Update Resources'">
                  <button mat-button type="submit" color="primary"
                  [disabled]="!userRoles.blueprintModify || disableUnmanaged() || isSubscription()" (click)="resourceFormSubmit()">{{editForm?"Update":"Save"}}
                </button></div>
              </div>
            </mat-card-actions>
            </form>
          </mat-card-content>   
          </div>
          </mat-card>
        </mat-expansion-panel>
      </section>
      </div>
    </ng-container>

    <ng-container *ngIf="sideMenuContext === 'retrigger'">
      <div class="drawer-header">
        <span>RETRIGGER RESOURCES</span>
      </div>
      <section class="form-grid">
        <mat-divider></mat-divider>
          <mat-card style="filter: brightness(110%);">
            <mat-card-header>
              Failed Resources
            </mat-card-header><br>
            <mat-card-content>
              <mat-table #table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                    >
                    </mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> Resource Name </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.ResourceName }} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef> Resource type </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.ResourceType }} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="operation">
                  <mat-header-cell *matHeaderCellDef> Operation </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.Operation }} </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></mat-row>
                <ng-container *matNoDataRow>
                  <br>No Resources to Retrigger<br><br>
                </ng-container>              
              </mat-table>
            </mat-card-content>
            <mat-form-field class="rmv-field-padding" appearance="outline" style="padding: 10px;">
              <mat-label>Comments</mat-label>
              <input matInput [(ngModel)]="comment" placeholder="Comments" #input>
            </mat-form-field>
            <mat-card-actions align="end" class="bottom">
              <span [matTooltip]="!isAllSelected() ? 'Select all to Cancel Retriggering': 'Cancel Retriggering'">
              <button mat-button color="warn" (click)="retrigger(true)" [disabled]="!isAllSelected()">Cancel Retrigger</button>
            </span>
              <button mat-button color="primary" (click)="retrigger(false)" matTooltip="Retrigger Resources">Retrigger</button>
            </mat-card-actions>
          </mat-card>
      </section>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
</app-navigation>
<tour-step-template></tour-step-template>

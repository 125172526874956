import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsService {

  GET_BP_DEPLOYMENT_STATUS_LIST = environment.API_URL + 'BlueprintDeploymentStatus';
  constructor(private http: HttpClient) {
  }

  getBpDeploymentsList() {
    const asc = 'desc';
    const params = new HttpParams()
      .set('sortOrder', asc)
      .set('sortField', "draftName")
      .set('pageNumber', 1)
      .set('pageSize', 1)
    return this.http.get<object>(this.GET_BP_DEPLOYMENT_STATUS_LIST, { observe: 'response', params });
  }

  getBpDeployments(sort: string, order: string, page: number, filter: string): Observable<any> {
    let params = new HttpParams()
      .set('sortField', sort)
      .set('sortOrder', order)
      .set('pageSize', '30')
      .set('searchTerm', filter) 
      .set('pageNumber', (page + 1).toString());

    return this.http.get<any>(this.GET_BP_DEPLOYMENT_STATUS_LIST, { params });
  }
}
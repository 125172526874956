import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AesencryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class BlueprintsService {

  GET_EMPTY_SUBSCRIPTION_TEMPLATE_URL = environment.API_URL + 'BluePrintSubscriptionTemplate';
  GET_BP_RESOURCE_ICON_URL = environment.API_URL + 'BluePrintResourceIconPanel';
  GET_BP_RESOURCE_FORM_URL = environment.API_URL + 'BluePrintResourceFormTemplate'
  GET_BP_USER_DRAFTS_URL = environment.API_URL + 'BluePrintUserDraftsList'
  GET_BP_USER_DRAFTS_JSON_URL = environment.API_URL + 'BluePrintUserDraft'
  POST_BP_USER_DRAFT_URL = environment.API_URL + 'BluePrintUserDraft';
  POST_BP_RESOURCE_DRAFT_URL = environment.API_URL + 'BluePrintResourceData';
  DELETE_BP_RESOURCE_FORM_URL = environment.API_URL + 'BluePrintResourceData';
  POST_BP_RESOURCE_PROPERTIES_URL = environment.API_URL + 'BluePrintResourceDataProperties';
  POST_BP_COST_CALCULATE_URL = environment.API_URL + 'BluePrintCostCalculate'; //Post to trigger cost pipeline
  GET_BP_COST_POLLING_STATUS_URL = environment.API_URL + 'BluePrintCostCalculationStatus';  //poll to get infracost status
  GET_BP_INFRACOST_JSON_URL = environment.API_URL + 'BluePrintUserDraft';  //API to get infracost response json
  POST_USER_BP_APPROVAL_URL = environment.API_URL + 'BluePrintApprovalRequest';  //After getting cost submit for approval
  POST_BP_CLONE_URL = environment.API_URL + 'BluePrintClone';  //Done
  POST_BP_COPY_URL = environment.API_URL + 'BluePrintCloneTemplate';
  POST_ACTIVATION_STATUS_URL = environment.API_URL + 'BluePrintDraftTemplate';
  GET_ACTIVATION_STATUS_URL = environment.API_URL + 'BluePrintDraftTemplate';
  GET_BP_DEPLOYMENT_STATUS_URL = environment.API_URL + 'BluePrintDeploymentStatusDetails';
  POST_RETRIGGERED_RESOURCES_URL = environment.API_URL + 'BluePrintOrchestratorRetrigger';
  currentMessage: any;

  constructor(private http: HttpClient, private aesencryptionService: AesencryptionService) {
  }

  public getEmptySubscriptionTemplate() {
    return this.http.get(this.GET_EMPTY_SUBSCRIPTION_TEMPLATE_URL, { observe: 'response' });
  }

  public getBPIconPanel() {
    return this.http.get(this.GET_BP_RESOURCE_ICON_URL, { observe: 'response' });
  }

  public postSubscriptionDraft(subscriptionJson: object, draftName: string) {
    const params = new HttpParams()
      .set('draftName', draftName)
    return this.http.post(this.POST_BP_USER_DRAFT_URL, subscriptionJson, { params, observe: 'response' });
  }

  // public postSubscriptionDraft(subscriptionJson: object, draftName: string){
  //   const params = new HttpParams()
  //   .set('draftName', draftName);
  //   const body =  JSON.stringify(this.aesencryptionService.encryptUsingAES256(JSON.stringify(subscriptionJson)));
  //   const headers = new HttpHeaders().set('Content-Type', 'text/json');
  //   return this.http.post(this.POST_BP_USER_DRAFT_URL, body, {params, headers, observe: 'response' });
  // }

  public postBPResourceForm(body: object, formid: string, dguid: string, uguid: string) {
    const params = new HttpParams()
      .set('formid', formid)
      .set('uguid', uguid)
      .set('dguid', dguid);
    return this.http.post(this.POST_BP_RESOURCE_DRAFT_URL, body, { params, observe: 'response' });
  }

  public putBPResourceForm(uguid: string, dguid: string, body: object) {
    const params = new HttpParams()
      .set('uguid', uguid)
      .set('dguid', dguid);
    return this.http.put(this.POST_BP_RESOURCE_DRAFT_URL, body, { params, observe: 'response' });
  }

  public deleteBPResourceForm(uguid: string, dguid: string) {
    const params = new HttpParams()
      .set('uguid', uguid)
      .set('dguid', dguid);
    return this.http.delete(this.DELETE_BP_RESOURCE_FORM_URL, { params: params, observe: 'response' });
  }

  public putBPResourceProperties(Uguid: string, body: object) {
    const params = new HttpParams()
      .set('Uguid', Uguid);
    return this.http.put(this.POST_BP_RESOURCE_PROPERTIES_URL, body, { params, observe: 'response' });
  }

  getBpResourceForm(FormId: string) {
    const params = new HttpParams()
      .set('FormId', FormId)
    return this.http.get(this.GET_BP_RESOURCE_FORM_URL, { params, observe: 'response' })
  }

  getBpUserDrafts() {  // correct api call with headers and body return
    return this.http.get(this.GET_BP_USER_DRAFTS_URL, { observe: 'response' })
  }

  getBpUserDraftJson(Dguid: string) {
    const params = new HttpParams()
      .set('Dguid', Dguid)
    return this.http.get(this.GET_BP_USER_DRAFTS_JSON_URL, { params, observe: 'response' })
  }

  getBpDeploymentStatus(Dguid: string) {
    const params = new HttpParams()
      .set('dguid', Dguid)
    return this.http.get(this.GET_BP_DEPLOYMENT_STATUS_URL, { params, observe: 'response' })
  }
  public postRetriggeredResources(Dguid: string, Uguids: any, comments: string, isCancelled: boolean) {
    const body = {
      dguid: Dguid,
      uguids: Uguids,
      comment: comments,
      isCancelled: isCancelled
    }
    return this.http.post(this.POST_RETRIGGERED_RESOURCES_URL, body, { observe: 'response' });
  }

  //Submission phase
  public postBPCostCalculate(Dguid: string) {
    const params = new HttpParams()
      .set('Dguid', Dguid)
    return this.http.post(this.POST_BP_COST_CALCULATE_URL, null, { params: params, observe: 'response' });
  }

  getBpCostPolling(Dguid: string) {
    const params = new HttpParams()
      .set('Dguid', Dguid)
    return this.http.get(this.GET_BP_COST_POLLING_STATUS_URL, { params: params, observe: 'response' })
  }

  getBpCostJson(Dguid: string) {
    const params = new HttpParams()
      .set('Dguid', Dguid)
    return this.http.get(this.GET_BP_INFRACOST_JSON_URL, { params: params, observe: 'response' })
  }

  public postBPApproval(Dguid: string, summary: string) {
    const params = new HttpParams()
      .set('Dguid', Dguid)
      .set('summary', summary);
    return this.http.post(this.POST_USER_BP_APPROVAL_URL, null, { params: params, observe: 'response' });
  }



  public cloneBlueprint(subscriptionId: string, draftName: string) {
    const params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('draftName', draftName);
    return this.http.post(this.POST_BP_CLONE_URL, null, { params, observe: 'response' });
  }
  public copyBlueprint(dguid: string, draftName: string, newSubName: string) {
    const params = new HttpParams()
      .set('dguid', dguid)
      .set('draftName', draftName)
      .set('newSubName', newSubName)
    return this.http.post(this.POST_BP_COPY_URL, null, { params, observe: 'response' });
  }
  public postActivationStatus(dguid: string, activationStatus: boolean, shareToRoleIds: any) {
    const body = {
      Dguid: dguid,
      ShareToRoles: shareToRoleIds.map((roleId: any) => ({ roleId })),
      IsActive: activationStatus
    }
    return this.http.post(this.POST_ACTIVATION_STATUS_URL, body, { observe: 'response' });
  }
  getActivationStatus(Dguid: string) {
    const params = new HttpParams()
      .set('dguid', Dguid)
    return this.http.get(this.GET_ACTIVATION_STATUS_URL, { params: params, observe: 'response' })
  }
}
<app-navigation>
  <main style="background-color: #222522;">
      <section #landingPageDashboards class="landing-page-dashboards">
              <div class="content-wrapper">
                <section class="info-column" [@fadeInAnimation]="isDashboardSectionInView ? 'fadeIn' : 'fadeOut'">
                  <header class="header">
                    <h1 class="title">
                      <span class="highlight">Unified Cloud</span><br>
                      <span>Dashboards</span>
                    </h1>
                    <p class="description">
                      Gain full visibility of all your subscriptions, resource costs, approval statuses, and deployment progress in one streamlined dashboard.
                    </p>
                    <div class="action-buttons">
                      <button mat-fab extended color="primary" [routerLink]="userRoles.blueprintView ? ['/dashboard'] : null">
                        <span class="button-content">CONTINUE <mat-icon><i-tabler name="arrow-right"></i-tabler></mat-icon></span>
                        </button>
                    </div>
                  </header>
                </section>
                <section class="image-column">
                  <div class="radial-container" @enterXAnimation></div>
                  <img src="./assets/images/sub-table.svg" alt="" style="left:3%;width: 45%;" [@fadeInAnimation]="isDashboardSectionInView ? 'fadeIn' : 'fadeOut'"/>
                  <img src="./assets/images/resource-details.svg" alt="" style="left: 9%;width: 45%;" [@fadeInAnimation]="isDashboardSectionInView ? 'fadeIn' : 'fadeOut'"/>
                  <img src="./assets/images/resource-overview.svg" alt="" style="top:3%;left: 12%;width: 75%;" [@fadeInAnimation]="isDashboardSectionInView ? 'fadeIn' : 'fadeOut'"/>
                </section>
              </div>
      </section>
      <section #landingPageMonitoring class="landing-page-monitoring" style="height: 85%;">
        <header class="monitoring-header">
          <h1 class="title">
            <span class="highlight">Advanced</span> Observability <span class="highlight">Tools</span>
          </h1>
          <p class="description">
            Utilize advanced observability solutions for real-time monitoring, logging, and tracing, ensuring optimal cloud performance and swift issue resolution.
          </p>
        </header>
        <div class="monitoring-feature-grid" [@enterYAnimation]="isMonitoringSectionInView ? 'in' : 'out'">
          <mat-card class="feature-card">
            <div class="feature-content">
              <div>
              <img class="monitoring-logo" src="./assets/images/grafana_icon.svg" alt=""/>
              </div>
              <div class="feature-text">
                <p class="feature-title"><strong>Dashboards</strong></p>
                <p class="feature-description">
                  <strong>Grafana</strong> integration to Transform raw data into stunning visuals. Uncover hidden insights in real time.
                </p>
              </div>
            </div>
          </mat-card>
          <mat-card class="feature-card">
            <div class="feature-content">
            <div>
            <img class="monitoring-logo" src="./assets/images/loki_icon.svg" alt=""/>
            </div>
            <div class="feature-text">
              <p class="feature-title"><strong>Logs</strong></p>
              <p class="feature-description">
                <strong>Loki</strong> Integration for Log management without limits. Efficient, scalable, and cost-effective log collection and querying.
              </p>
            </div>
          </div>
          </mat-card>
          <mat-card class="feature-card"> 
            <div class="feature-content">
              <div>
              <img class="monitoring-logo" src="./assets/images/mimir_logo.svg" alt=""/>
              </div>
              <div class="feature-text">
                <p class="feature-title"><strong>Metrics</strong></p>
                <p class="feature-description">
                  <strong>Mimir</strong> integration to Scale your metrics without limits. High-performance time-series database for massive data management.
                </p>
              </div>
            </div>
          </mat-card>
          <mat-card class="feature-card">
            <div class="feature-content">
              <div>
              <img class="monitoring-logo" src="./assets/images/tempo_logo.svg" alt=""/>
              </div>
              <div class="feature-text">
                <p class="feature-title"><strong>Traces</strong></p>
                <p class="feature-description">
                  <strong>Tempo</strong> Integration built to scale effortlessly while simplifying trace data storage and retrieval.
                </p>
              </div>
            </div>
          </mat-card>
        </div>
      </section>
      <section #landingPageBlueprints class="landing-page-blueprints">
                <div class="content-wrapper">
                  <section  class="info-column" [@fadeInAnimation]="isBlueprintsSectionInView ? 'fadeIn' : 'fadeOut'">
                  <header class="header">
                    <h1 class="title">
                      <span class="highlight">Blueprints</span><br>
                      <span>Infrastructure as Diagram</span>
                    </h1>
                    <p class="description">
                      Visualize, collaborate, and cost-optimize your cloud infrastructure. Create detailed blueprints, share them with your team, and get instant cost estimates to make informed decisions.
                    </p>
                    <div class="action-buttons">
                      <button mat-fab extended color="primary" (click)="handleButtonClick()">
                        <span class="button-content">{{userRoles.blueprintView ? 'CONTINUE' : 'GET ACCESS' }}
                        <mat-icon><i-tabler name="arrow-right"></i-tabler></mat-icon>
                      </span>
                      </button>
                    </div>
                  </header>
                </section>
                <section style="position: relative;">
                <div>
                    <img class="blueprints-img" src="./assets/images/Blueprints.svg" alt=""/>
                </div>
              </section>
              </div>
      </section>
      <app-footer></app-footer>
  </main>
</app-navigation>

import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResourceNameService } from './resource-name.service';

interface ResourceNameOptions {
  count: number;
  subscriptionIdentifier: string;
  env: string;
  identifier: string;
  appFam: string;
  applicationFamily: string;
  subscriptionName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceDataService {
  subscriptionName: any;
  blueprintJson: any;
  createdTableDataSource = new MatTableDataSource<any>();
  updatedTableDataSource = new MatTableDataSource<any>();
  deletedTableDataSource = new MatTableDataSource<any>();
  syncedTableDataSource = new MatTableDataSource<any>();

  constructor(private resourceNameService: ResourceNameService) { }
  bpReviewData(blueprintJson: any) {
    this.blueprintJson = blueprintJson;
    const created: any[] = [];
    const updated: any[] = [];
    const deleted: any[] = [];
    const synced: any[] = [];
    for (const resourceType of blueprintJson.resourceInventoryData) {
      for (const resourceList of resourceType.resourceList) {
        const resourceName = this.getResourceDetails(resourceList.resourceData);
        resourceList.resourceData.resourceName = resourceName;
        if (resourceList.resourceData.userActionType === 'create' && !resourceList.resourceData.isChanged) {
          created.push(resourceList.resourceData)
        } else if (resourceList.resourceData.userActionType === 'update') {
          updated.push(resourceList.resourceData)
        } else if (resourceList.resourceData.userActionType === 'delete') {
          deleted.push(resourceList.resourceData)
        } else if (resourceList.resourceData.userActionType === '' && resourceList.resourceData.resourceStatus !=="deleted" && resourceList.resourceData.isChanged === true) {
          synced.push(resourceList.resourceData);
        }
      }
    }
    if (created.length > 0) {
      const createdCosts = this.calculateTotalCosts(created);
      if(createdCosts[0] - createdCosts[1] !== 0){
      created.push({
        previousTotalCost: createdCosts[0],
        currentTotalCost: createdCosts[1]
      });
    }
    }
    if (updated.length > 0) {
      const updatedCosts = this.calculateTotalCosts(updated);
      if(updatedCosts[0] - updatedCosts[1] !== 0){
      updated.push({
        previousTotalCost: updatedCosts[0],
        currentTotalCost: updatedCosts[1]
      });
    }
    }
    if (deleted.length > 0) {
      const deletedCosts = this.calculateTotalCosts(deleted);
      if(deletedCosts[0] - deletedCosts[1] !== 0){
      deleted.push({
        previousTotalCost: deletedCosts[0],
        currentTotalCost: deletedCosts[1]
      });
    }
    }
    if (synced.length > 0) {
      const syncedCosts = this.calculateTotalCosts(synced);
      if(syncedCosts[0] - syncedCosts[1] !== 0){
      synced.push({
        previousTotalCost: syncedCosts[0],
        currentTotalCost: syncedCosts[1]
      });
    }
    }
    return { created, updated, deleted, synced };
  }

  calculateTotalCosts(data:any) {
    let totalPreviousCost = 0;
    let totalCurrentCost = 0;
    for(const resource of data){
      totalPreviousCost += resource.previousCost;
      totalCurrentCost += resource.currentCost;
    }
    return [totalPreviousCost, totalCurrentCost];
  }

  getResourceDetails(resourceData: any) {
    const resourceDisplayName = resourceData?.resourceDisplayName || '';
    const azid = resourceData?.azid;
    if (azid && azid !== "") {
      return azid.split('/').pop();
    }
    const countObj: any = {};
    countObj[resourceDisplayName] = (countObj[resourceDisplayName] || 0) + (azid ? 0 : 1);
    this.subscriptionName = this.getPropertyValue(this.blueprintJson.subscriptionData.resourceData.resourcePropertyList,'subscriptionFullyQualifiedName');
    const applicationFamily = this.getPropertyValue(this.blueprintJson.subscriptionData.resourceData.resourcePropertyList,'applicationFamily');
    const subscriptionNameString = this.subscriptionName?.split('-') || [];
    const [subscriptionIdentifier, env, appFam] = this.getLastElements(subscriptionNameString,3);
    const identifier = (resourceData?.ticketId || '').replace(/^EAA-(\w+)/,'$1');
    const options: ResourceNameOptions = {
      count: countObj[resourceDisplayName],
      subscriptionIdentifier,
      env,
      identifier,
      appFam,
      applicationFamily,
      subscriptionName: this.subscriptionName,
    };
    const resourceName = this.resourceNameService.getResourceName(resourceData.resourceNameFormula || resourceDisplayName,options);
    return resourceName;
  }
  getPropertyValue(resourcePropertyList: any[], propertyName: string) {
    const propertyObj = resourcePropertyList.find(
      (rcs: any) => rcs?.propertyName === propertyName
    );
    return propertyObj ? propertyObj.propertyValue : '';
  }
  getLastElements(array: [], count: number) {
    return array.slice(Math.max(array.length - count, 0));
  }
}

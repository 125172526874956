import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { BlueprintsService } from 'src/services/blueprints.service';
import { NavigationService } from 'src/services/navigation.service';
import { AesencryptionService } from 'src/services/encryption.service';
import { CoreService } from 'src/app/components/shared/snackbar/snackbar.service';
import { Base64Service } from 'src/services/base64.service';
import { Location } from '@angular/common';
import { RoleData } from '../../roles-table/roles';

@Component({
  selector: 'app-blueprint-new-subscription',
  templateUrl: './blueprint-new-subscription.component.html',
  styleUrls: ['./blueprint-new-subscription.component.scss'],
})
export class BlueprintNewSubscriptionComponent implements OnInit{
  subName = false;
  disablefield = true;
  newdraftControl = new FormControl('');
  subscriptionUserData: any;
  subscriptionformGroup: any = FormGroup;
  userRoles: RoleData;
  subscriptionImage: any;
  loadSubscriptionForm = false;
  maxLength!: number;

  constructor(
    private navigationService: NavigationService,
    private _blueprintService: BlueprintsService,
    private route: Router,
    private aesencryptionService: AesencryptionService,
    private coreService: CoreService,
    private location: Location,
    private base64Service: Base64Service,
    private _coreService: CoreService
  ) {
    this.userRoles = JSON.parse(this.aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')));
  }

  ngOnInit() {
    this.navigationService.setTitle('Create a New Subscription');
    this.EmptyBPForm();
    this.setSubscriptionNameMaxLength();
  }

  //get empty json for new subscription
  EmptyBPForm() {
    this._blueprintService.getEmptySubscriptionTemplate().subscribe(
      (res: HttpResponse<any>) => {
        this.subscriptionUserData = res.body;
        if (this.subscriptionUserData) {
          this.subscriptionformGroup = this.createSubscriptionFormGroup();
          this.subscriptionImage = this.base64Service.decode(
            this.subscriptionUserData.resourceGeneralIconUrl
          );
          this.loadSubscriptionForm = true;
        }
      },
    );
  }

  createSubscriptionFormGroup() {
    const formGroup = new FormGroup({});
    const subscriptionJsonData = this.subscriptionUserData;
    const subscriptionresourcePropertyList =
      subscriptionJsonData.resourceData.resourcePropertyList;
    for (const subscriptionresourceProperty of subscriptionresourcePropertyList) {
      if (subscriptionresourceProperty.propertyNameAlias) {
        let formControl;
        if (subscriptionresourceProperty.userInput) {
          formControl = new FormControl(
            subscriptionresourceProperty.propertyValue,
            Validators.required
          );
        } else {
          formControl = new FormControl(
            subscriptionresourceProperty.propertyValue
          );
        }
        formGroup.addControl(
          subscriptionresourceProperty.propertyName,
          formControl
        );
      }
    }
    return formGroup;
  }

  subscriptionformSubmit() {
    const subscriptionResourcePropertyList =
      this.subscriptionUserData.resourceData.resourcePropertyList;
    for (const field of subscriptionResourcePropertyList) {
      if (field.propertyNameAlias) {
        field.propertyValue =
          this.subscriptionformGroup.value[field.propertyName];
      }
    }
    const draftName = this.newdraftControl.value ?? ''; // Set default value to empty string if null
    const subscriptionPropertyData = this.subscriptionUserData.resourceData.resourcePropertyList;
    this._blueprintService
      .postSubscriptionDraft(subscriptionPropertyData, draftName)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.route.navigateByUrl(`/blueprint/edit/${response.body}`);
        },
        error: () => {
          this.coreService.openSnackBar(
            'Error while creating draft',
            2000,
            'warn-snackbar'
          );
        },
      });
  }

  goBack() {
    this.location.back();
  }

  omit_special_char(special: KeyboardEvent) {
    const k = special.key;
    return (
      RegExp(/[a-z0-9]/).exec(k) ??
      k === 'Backspace'
    );
  }
  setSubscriptionNameMaxLength(){
    if(this.userRoles.blueprintTemplateAdmin){
      this.maxLength=7;
    }
    else{
      this.maxLength=10;
    }
  }
}

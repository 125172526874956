import { Component, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { NavigationService } from 'src/services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { BlueprintsService } from 'src/services/blueprints.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { TourService } from 'ngx-ui-tour-md-menu';
import { ActivatedRoute, Router } from '@angular/router';
import { BlueprintDataService } from 'src/services/blueprint-data.service';
import { BlueprintResourceFormDialogComponent } from '../../dialog/blueprint-resource-form-dialog/blueprint-resource-form-dialog.component';
import { MatDrawer } from '@angular/material/sidenav';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { DialogService } from 'src/services/confirm-dialog.service';
import { BlueprintCanvasComponent } from './blueprint-canvas/blueprint-canvas.component';
import { Observable } from 'rxjs/internal/Observable';
import { AesencryptionService } from 'src/services/encryption.service';
import { Base64Service } from 'src/services/base64.service';
import { RoleData } from '../roles-table/roles';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ResourceNameService } from 'src/services/resource-name.service'
import { ResourceNameOptions } from 'src/app/models/IResourceNameOptions';
import { Subscription } from 'rxjs';

export interface Element {
  ResourceName: string;
  ResourceType: string;
  Operation: string;
  Uguid: string;
}

@Component({
  selector: 'app-blueprint',
  templateUrl: './blueprint.component.html',
  styleUrls: ['./blueprint.component.scss'],
})

export class BlueprintComponent implements OnInit, OnDestroy {
  blueprintJson: any;
  bluePrintResourceIconPanel: any[] = [];
  resourceIconShow = false;
  resourceFormGroup: any = FormGroup;
  resourceInventoryData: any[] = [];
  infracostjson: any[] = [];
  dguid = "";
  formId = "";
  transactions: any[] = []
  totalcost = 0;
  costpercentage = 0;
  resourcepercentage = 0;
  uguid = "";
  currentResourcePropertyList: any[] = [];
  displayName: any;
  editForm = false;
  resourceCount = 0;
  private readonly tourService = inject(TourService);
  loadBpJson = false;
  enableToolbar = false;
  @ViewChild('drawer') drawer!: MatDrawer;
  userRoles: RoleData;
  filteredList: any[] = [];
  charCount = 0;
  @ViewChild(BlueprintCanvasComponent) blueprintCanvasComponent!: BlueprintCanvasComponent;
  displayResourceType = '';
  resourceType = '';
  currentResourceData: any;

  @HostListener('window:resize', ['$event'])
  displayedColumns = ['select', 'name', 'type', 'operation'];
  dataSource = new MatTableDataSource<Element>();
  selection = new SelectionModel<Element>(true, []);
  selectedUguids!: string[];
  comment: string = "";

  private retriggerDrawerSubscription!: Subscription;
  sideMenuContext: string = "";
  cataloguePanel: boolean = true;
  hasUnsavedChangesFlag!: boolean;

  constructor(
    private navigationService: NavigationService,
    public _dialog: MatDialog,
    private _blueprintService: BlueprintsService,
    private activatedRoute: ActivatedRoute,
    private blueprintDataService: BlueprintDataService,
    private _coreService: CoreService,
    private dialogService: DialogService,
    private aesencryptionService: AesencryptionService,
    private base64Service: Base64Service,
    private resourceNameService: ResourceNameService,
    private route: Router,
  ) {
    this.userRoles = JSON.parse(aesencryptionService.decryptUsingAES256(sessionStorage.getItem('userRoles')) || '');
  }

  ngOnInit() {
    this.navigationService.setTitle('Blueprints');
    this.dguid = this.activatedRoute.snapshot.params['id'];
    this.getBlueprintJson(this.dguid);
    this.getResourceIconPanel();
    this.enableToolbar = true;

    this.retriggerDrawerSubscription = this.blueprintDataService.retriggerDrawerObservable$.subscribe(() => {
      this.openRetrigger();
    });
  }


  ngOnDestroy() {
    this.retriggerDrawerSubscription.unsubscribe();
  }

  public hasUnsavedChanges(): boolean {
    // Implement logic to check if there are unsaved changes
    return this.hasUnsavedChangesFlag;
  }

  startTour() {
    this.tourService.start();
  }

  getResourceIconPanel() {
    this._blueprintService.getBPIconPanel().subscribe({
      next: (res: HttpResponse<any>) => {
        const filteredResponse = this.filterIconPanel(res.body);
        this.bluePrintResourceIconPanel = filteredResponse;
        this.filteredList = this.bluePrintResourceIconPanel;
      },
      error: () => {
        this._coreService.openSnackBar('Error fetching Blueprint Icon Panel', 2000, 'warn-snackbar');
      }
    });
  }

  iconPanelSearch(filterValue: string) {
    this.filteredList = this.bluePrintResourceIconPanel.filter((resource) => {
      return resource.resourceGeneralDisplayName.toLowerCase().includes(filterValue.toLowerCase()) ||
        resource.formsList.some((form: any) => form.resourceDisplayName.toLowerCase().includes(filterValue.toLowerCase()));
    });
    this.charCount = filterValue.length
  }

  private filterIconPanel(iconPanel: any[]): any[] {
    iconPanel.forEach((resource) => {
      resource.formsList.forEach((form: any) => {
        form.resourceIconUrl = this.base64Service.decode(form.resourceIconUrl);
      })
    })
    return iconPanel.filter((el: { resourceType: string }) => el.resourceType !== "Microsoft.Resources/subscriptions");
  }

  getBlueprintJson(dguid: string) {
    this._blueprintService.getBpUserDraftJson(dguid).subscribe({
      next: (response: HttpResponse<any>) => {
        this.handleBlueprintJsonResponse(response);
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Blueprint', 2000, 'warn-snackbar');
        this.loadBpJson = true;
      }
    });
  }

  private handleBlueprintJsonResponse(response: HttpResponse<any>) {
    this.blueprintJson = response.body;
    this.blueprintDataService.setBlueprintData(this.blueprintJson);
    this.dguid = this.blueprintJson.userRequestInfo.dguid;
    this.resourceIconShow = true;
    this.loadBpJson = true;
    this.updateResourceInventoryData(this.blueprintJson.resourceInventoryData);
  }

  private updateResourceInventoryData(data: any) {
    if (data) {
      this.resourceInventoryData = data;
      this.resourceFormGroup = this.createInventoryFormGroup();
    }
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  drag(event: any) {
    event.dataTransfer.setData("text", event.target.id);
    this.formId = event.target.id;
    this.editForm = false;
  }

  drop(event: any) {
    event.preventDefault();
    this.resourceTypeDialog(this.blueprintJson, this.formId);
  }

  selectResourceNode(nodeData: any) {
    if (nodeData.nodeType === 'resource' || nodeData.nodeType === 'subResource' || nodeData.nodeType === 'subscription') {
      this.uguid = nodeData.id;
      this.editForm = true;
      this.resourceType = nodeData.type;
      this.setResourceNodeProperties(nodeData);
      this.resourceFormGroup = this.createInventoryFormGroup();
      this.sideMenuContext = "configurator";
      this.drawer.toggle();
      this.hasUnsavedChangesFlag=true;
    }
  }

  setResourceNodeProperties(nodeData: any) {
    if (nodeData.nodeType === 'subscription') {
      const subscriptionData = this.blueprintJson.subscriptionData;
      this.displayName = nodeData.resourceStatus === 'fullyQualified' ? nodeData.name : "New Subscription: " + nodeData.name;
      this.currentResourcePropertyList = subscriptionData.resourceData.resourcePropertyList;
      this.currentResourceData = subscriptionData.resourceData;
    }
    else{
    for (const resourceType of this.blueprintJson.resourceInventoryData) {
      for (const resourceList of resourceType.resourceList) {
        if (resourceList.resourceData.uguid === nodeData.id) {
          this.currentResourcePropertyList = resourceList.resourceData.resourcePropertyList;
          this.currentResourceData = resourceList.resourceData;
          const azid = resourceList.resourceData.azid;
          if (azid == null || azid === '') {
            this.displayName = "New " + resourceList.resourceData.resourceDisplayName;
          }
          else {
            const splitAzid = azid.split('/');
            const resourceName = splitAzid[splitAzid.length - 1];
            this.displayName = resourceName;
          }
        }
      }
    }
  }
  }

  openCatalogue(opencataloguePanel: boolean) {
    this.cataloguePanel = opencataloguePanel;
  }

  resourceTypeDialog(bpJson: object, formId: string) {
    const dialogRef = this._dialog.open(BlueprintResourceFormDialogComponent, { width: '400px', data: { bpJson: (bpJson), formId: formId, editForm: this.editForm, dguid: this.dguid } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.highlightResourceNode(result);
      }
    }
    )
  }

  highlightResourceNode(uguid: string) {
    this.blueprintCanvasComponent.highlightNode(uguid);
  }

  //Working fine
  createInventoryFormGroup() {
    const formGroup = new FormGroup({});
    const currentResourcePropertyList = this.currentResourcePropertyList;
    for (const currentResourceProperty of currentResourcePropertyList) {
      if (currentResourceProperty.propertyNameAlias) {
        let formControl;
        if (currentResourceProperty.userInput) {
          formControl = new FormControl(
            currentResourceProperty.propertyValue,
            Validators.required
          );
        }
        else {
          if (currentResourceProperty.itemList) {
            const itemListArray =
              currentResourceProperty.itemList.split(';');
            currentResourceProperty.propertyValue =
              itemListArray[0];
          }
          formControl = new FormControl(
            currentResourceProperty.propertyValue
          );
        }
        if (!currentResourceProperty.editable && this.currentResourceData.resourceStatus === 'fullyQualified') {
          formControl.disable(); // Disable the form control if editable is false
        }
        formGroup.addControl(currentResourceProperty.propertyName, formControl)
      }
    }
    return formGroup;
  }

  resourceFormSubmit() {
    const currentFormValues = this.resourceFormGroup.getRawValue();
    this.updateResourceProperties(currentFormValues);
    this.handleResourceUpdate();
  }

  private updateResourceProperties(currentFormValues: any): void {
    this.currentResourcePropertyList.forEach(field => {
      if (field.propertyNameAlias) {
        field.propertyValue = currentFormValues[field.propertyName];
      }
    });
  }

  private handleResourceUpdate(): void {
    if (this.editForm) {
      this.confirmResourceUpdateDialog();
    }
  }

  private confirmResourceUpdateDialog(): void {
    this.drawer.toggle();
    this.dialogService
      .confirmDialog({
        title: "Update Resource",
        message: "On click resource will be updated",
        confirmCaption: "Update",
        cancelCaption: "Cancel",
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.updateResourceForm();
        }
      });
  }

  private updateResourceForm(): void {
    this.loadBpJson=false;
    this._blueprintService.putBPResourceForm(this.uguid, this.dguid, this.currentResourcePropertyList)
      .subscribe({
        next: () => {
          if (this.currentResourceData.resourceStatus === 'fullyQualified') {
            this.updateUserActionType(this.uguid, 'update');
          }
          this.loadBpJson=true;
          this._coreService.openSnackBar('Resource Details Updated', 1000, 'default-snackbar');
          this.hasUnsavedChangesFlag=false;
        },
        error: () => {
          this.loadBpJson=true;
          this._coreService.openSnackBar('Error while updating resource', 2000, 'warn-snackbar');
        }
      });
  }

  deleteResource(uguid: string) {
    this.drawer.toggle();
    this.confirmDeleteResource().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteResourceFromService(uguid);
      }
    });
  }

  private confirmDeleteResource(): Observable<boolean> {
    return this.dialogService
      .confirmDialog({
        title: "Delete Resource",
        message: this.currentResourceData.userActionType === 'create' ? "On clicking Delete your changes cannot be reverted and "+ (this.isSubscription() ? "subscription" : "resource") + " will be deleted." : "On clicking Delete your changes cannot be reverted and "+ (this.isSubscription() ? "subscription" : "resource") + " will be deleted from azure after approval.",
        confirmCaption: "Delete",
        cancelCaption: "Cancel",
      });
  }

  private deleteResourceFromService(uguid: string): void {
    this.loadBpJson=false;
    this._blueprintService.deleteBPResourceForm(uguid, this.dguid).subscribe({
      next: () => {
        if (this.currentResourceData.resourceStatus === 'fullyQualified') {
          this.getBlueprintJson(this.dguid);
        }
        else if (this.currentResourceData.resourceStatus === 'draft' || this.currentResourceData.userActionType === 'create') {
          this.currentResourceData.resourceDisplayName === 'Subscription' ? this.removeSubscription() : this.removeDraftResource(uguid);
        }
        this._coreService.openSnackBar('Deleted Successfully', 1000, 'default-snackbar');
      },
      error: () => {
        this.loadBpJson=true;
        this._coreService.openSnackBar('Error while deleting', 2000, 'warn-snackbar');
      }
    })
  }

  removeDraftResource(uguid: string) {
    this.blueprintJson.resourceInventoryData.forEach((item: any) => {
      item.resourceList = item.resourceList.filter((resource: any) => resource.resourceData.uguid !== uguid);
    });
    this.blueprintDataService.setBlueprintData(this.blueprintJson);
    this.loadBpJson=true;
  }
  removeSubscription() {
    this.blueprintJson.subscriptionData={};
    this.blueprintJson.resourceInventoryData={};
    this.blueprintDataService.setBlueprintData(this.blueprintJson);
    this.navigationService.setDisableCanDeactivateGuard(true);
    this.loadBpJson=true;
    this._coreService.openSnackBar('Deleted Subscription Successfully', 2000, 'success-snackbar');
    this.route.navigateByUrl('/blueprint/explorer');
  }

  updateUserActionType(uguid: string, userActionType: string) {
    this.blueprintJson.resourceInventoryData.forEach((item: any) => {
      const resourceIndex = item.resourceList.findIndex((resource: any) => resource.resourceData.uguid === uguid);
      if (resourceIndex !== -1) {
        item.resourceList[resourceIndex].resourceData.userActionType = userActionType;
      }
    });
    this.blueprintDataService.setBlueprintData(this.blueprintJson);
  }

  disableUnmanaged(): boolean {
    return this.currentResourceData.managedBy !== 'managed'; // Add this method
  }

  isSubscription(): boolean {
    return this.currentResourceData.resourceDisplayName === 'Subscription';
  }

  openRetrigger() {
    this.hasUnsavedChangesFlag=true;
    this.sideMenuContext = "retrigger";
    this._blueprintService.getBpDeploymentStatus(this.dguid).subscribe({
      next: (response: HttpResponse<any>) => {
        this.dataSource = new MatTableDataSource(response.body.deploymentStatus.filter((x: { Status: string; }) => x.Status === 'Failed'));
        const subscriptionName = response.body.subscriptionName;
        const subscriptionNameString = subscriptionName?.split('-') || [];
        const [appFam, env, subscriptionIdentifier] = this.resourceNameService.getLastElements(subscriptionNameString, 3);
        for (const resource of response.body.deploymentStatus) {
          if (resource.ResourceName === "") {
            const countObj: any = {};
            countObj[resource.ResourceType] = (countObj[resource.ResourceType] || 0) + (resource.azid ? 0 : 1);
            const options: ResourceNameOptions = {
              count: countObj[resource.ResourceType],
              subscriptionIdentifier,
              env,
              identifier: "xxxx",
              appFam,
              applicationFamily: "",
              subscriptionName: subscriptionName
            };
            resource.ResourceName = this.resourceNameService.getResourceName(resource.resourceNameFormula  || resource.ResourceType, options);
          }
        }
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Deployment Status for Resources', 2000, 'warn-snackbar');
      }
    });
    this.drawer.toggle();
  }

  retrigger(isCancelled: boolean) {
    this.loadBpJson=false;
    this.selectedUguids = this.selection.selected.map(item => item.Uguid);
    this._blueprintService.postRetriggeredResources(this.dguid, this.selectedUguids, this.comment, isCancelled).subscribe({
      next: (response: HttpResponse<any>) => {
        this.loadBpJson=true;
        isCancelled ? this._coreService.openSnackBar('Cancelled Retriggering Successfully!', 2000, 'success-snackbar'): this._coreService.openSnackBar('Retriggered Successfully!', 2000, 'success-snackbar');
        this.navigationService.setDisableCanDeactivateGuard(true);
        this.route.navigateByUrl('/deployment');
      },
      error: () => {
        this.loadBpJson=true;
        this._coreService.openSnackBar('Error while Retriggering', 2000, 'warn-snackbar');
      }
    });
    this.drawer.toggle();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}

<section class="section-p1">
<mat-divider style="margin-bottom: 5%;margin-top: 1%;"></mat-divider>
<footer>
    <div class="col">
       <img class="unimatrix-logo" src="./assets/images/UNIMATRIX_FINAL_FULL_DARK.svg" alt="">
       <div class="follow" style="margin-top: 1rem;">
          <p style="width: max-content;"><a href="mailto:ecad-support@devopscircuit.com">ecad-support&#64;devopscircuit.com</a></p>
          <a href="/landing">About us</a>
       </div>
    </div>
    <div class="col">
       <h4>Pages</h4>
       <div class="settingsCol">
       <a href="/dashboard">Dashboard</a>
       <a *ngIf="userRoles.blueprintApproval_L1 || userRoles.blueprintApproval_DevOps" href="/approval">Approvals</a>
       <a *ngIf="userRoles.grafanaUser" href="/monitoring">Monitor</a>
       <a *ngIf="userRoles.adminDevOps" href="/deployment">Deployments</a>
       <a *ngIf="userRoles.blueprintView" href="/blueprint/explorer">Blueprints</a>
      </div>
    </div>
    <div class="col">
      <h4>Settings</h4>
      <div class="settingsCol">
      <a *ngIf="userRoles.formbuilderView" href="/formbuilder">Formbuilder</a>
      <a *ngIf="userRoles.adminModule" href="/admin">Admin</a>
      <a *ngIf="userRoles.serviceAccount" href="/service">ServiceAccount</a>
      <a *ngIf="userRoles.adminRoleModule" href="/roles">RBAC</a>
      <a href="/tenants">Tenants</a>
      <a style="cursor: pointer;" (click)="logout()">Logout</a>
      </div>
   </div>
</footer>
<div class="copyright">
   <h3>
     2024, UNIMATRIX -<img style="position:absolute;width: 40px;top: 16px;margin-left: 5px;" src="./assets/images/logo-etap.svg" alt=""/>
   </h3>
</div>
</section>
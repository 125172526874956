import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AesencryptionService } from 'src/services/encryption.service';
import { CoreService } from '../../shared/snackbar/snackbar.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JwtServiceManagerModel } from 'src/app/models/IJwtServiceManagerModel';
import { ServiceAccountService } from './service-account.service';


@Component({
  selector: 'app-service-account',
  templateUrl: './service-account.component.html',
  styleUrls: ['./service-account.component.scss'],
})

export class ServiceAccountComponent implements OnInit {
  disableGenerate: boolean = true;
  disableDeactivate: boolean = true;
  expiryDate: any;
  userRoles: any;
  userInfo: {userId: string, UserName: string} | any;
  serviceAccountInfo : JwtServiceManagerModel | any;
  jwtSecret!: string;
  loadJwtTable: boolean = true;
  @ViewChild(MatPaginator) serviceAccountpaginator!: MatPaginator;
  serviceAccountColumns: { columnDef: string; header: string; cell: (element: JwtServiceManagerModel) => string; }[] | undefined;
  serviceAccountDataSource: MatTableDataSource<JwtServiceManagerModel> = new MatTableDataSource<JwtServiceManagerModel>([]);
  serviceAccountDisplayedColumns: string[] = [];
  
  constructor(
    private aesencryptionService: AesencryptionService,
    private _serviceAccountService: ServiceAccountService,
    private _coreService: CoreService
  ) {
    this.userRoles = JSON.parse(
      aesencryptionService.decryptUsingAES256(
        sessionStorage.getItem('userRoles') ?? ''
      )
    );
  }

  ngOnInit() {
    this.getUserDetails();
    this.getServiceAccount();
    this.getServiceAccountTable();
  }

  getServiceAccount() {
    this._serviceAccountService.getServiceAccountKey().subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.disableGenerate = false;
          this.disableDeactivate = true;
        } 
        else {
          this.disableGenerate = response.body.isActive;
          this.disableDeactivate = !response.body.isActive;
          this.serviceAccountInfo = response.body.isActive ? response.body : '';
          this.expiryDate = response.body.isActive ? new Date(response.body.JwtExpiry * 1000).toLocaleString() : '';
          this.jwtSecret = response.body.isActive ? this.aesencryptionService.decryptUsingAES256(response.body.JwtKey) : '';
        }
      },
      error: () => {
        this._coreService.openSnackBar('Error while fetching Key', 2000, 'warn-snackbar');
      },
    });
  }

  getUserDetails() {
    this._serviceAccountService.getUserDetails().subscribe({
      next: (response: HttpResponse<any>) => {
        this.userInfo = response.body;
      },
      error: () => {
        this._coreService.openSnackBar(
          'Error while Getting User Details',
          2000,
          'warn-snackbar'
        );
        this.userInfo = {"failed": "failed"};
      },
    });
  }

  generateJwt() {
    this.disableGenerate = true;
    this.disableDeactivate = false;
    this.expiryDate = '';
    this._serviceAccountService.generateServiceAccountKey().subscribe({
      next: (response: HttpResponse<any>) => {
        this.serviceAccountInfo = response.body;
        this.getServiceAccountTable();
        this.getUserDetails();
        this.jwtSecret = this.aesencryptionService.decryptUsingAES256(response.body.JwtKey);
        this.expiryDate = new Date(response.body.JwtExpiry * 1000);
        this.expiryDate = this.expiryDate.toLocaleString();
        this._coreService.openSnackBar('Generated Key Successfully',5000,'success-snackbar');
      },
      error: () => {
        this._coreService.openSnackBar('Error while Generating Key',2000,'warn-snackbar');
      },
    });
  }

  copyJWT() {
    this.disableGenerate = false;
    this.copyToClipboard(this.jwtSecret);
    this._coreService.openSnackBar('Copied Key to Clipboard',5000,'success-snackbar');
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.cssText = 'position: fixed; left: 0; top: 0; opacity: 0;';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getServiceAccountTable() {
    if (this.userRoles.adminDevOps) {
      this._serviceAccountService.getServiceAccountDetails().subscribe({
        next: (response: HttpResponse<any>) => {
          this.loadJwtTable = false;
          this.setServiceAccountTable(response.body);
        },
        error: () => {
          this._coreService.openSnackBar('Error while fetching Service Account Table', 2000, 'warn-snackbar');
        },
      });
    }
  }

  setServiceAccountTable(data: any) {
    this.serviceAccountColumns = [
      { columnDef: 'serviceId', header: 'Service Account User', cell: (element) => `${element.ServiceAccountUser}` },
      { columnDef: 'JwtExpiry', header: 'Key Expiry', cell: (element) => `${element.JwtExpiry}` },
      { columnDef: 'deactivatedBy', header: 'Deactivated By', cell: (element) => `${element.deactivatedByUser}` },
      // { columnDef: 'deactivateDate', header: 'Deactivate Date', cell: (element) => `${element.deactivateDate}` },
      { columnDef: 'isActive', header: 'Action', cell: (element) => `${element.isActive}` },
    ];
    
    this.serviceAccountDataSource = data;
    this.serviceAccountDataSource.paginator = this.serviceAccountpaginator;
    this.serviceAccountColumns.map(c => c.columnDef);
    this.serviceAccountDisplayedColumns = this.serviceAccountColumns.map(c => c.columnDef);
  }

  activateServiceAccount(serviceAccountDetails: JwtServiceManagerModel, resetJwtKey: boolean) {
    this._serviceAccountService.activateServiceAccountDetails(serviceAccountDetails, resetJwtKey).subscribe({
      next: () => {
        this._coreService.openSnackBar(serviceAccountDetails.isActive ? 'Deactivated Service Account': 'Activated Service Account',2000,'success-snackbar');
        this.getServiceAccountTable();
        if(this.userInfo.userId === serviceAccountDetails.userId)
          window.location.reload();
      },
      error: () => {
        this._coreService.openSnackBar('Error while Updating Service Account',2000,'warn-snackbar');
      },
    });
  }
}

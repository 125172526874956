import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  public title = new BehaviorSubject('Title');
  public isExpanded = new BehaviorSubject(true);

  setTitle(title: string) {
    this.title.next(title);
  }

}
